import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NoteModal = ({ openNote, closeNote, data }) => {
  const [showNote, setShowNote] = useState(false);

  useEffect(() => {
    if (openNote) {
      setShowNote(true)
    }
  }, [openNote]);
  
  const closeNoteModal = () => {
    setShowNote(false);
    closeNote(true)
  }
  
  return (
    <Modal
        id="modalBlueprint"
        show={showNote}
        onHide={() => closeNoteModal()}
        
        size="xl"
        centered
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-end" style={{ paddingBottom: "0px"}}>
          <button variant="transparent" className=' btn' onClick={() => closeNoteModal()}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
          <div>
            <h3>{data?.note_title || ""}</h3>
            <small className=' fw-medium'>{data?.created_date || ""} {data?.created_time || ""}  |  {data?.user || ""}</small>
            <p className='mt-3'>{data?.note || ""}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">

        </Modal.Footer>
      </Modal>
  );
}

export default NoteModal;
