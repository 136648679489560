import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import middleware from "../Api/Middleware";
import { useNavigate } from "react-router";
import TablePreset from "../Table/TablePreset";
import "../Table/Table.css";
import "../Main/Main.css";
import "./Customer.css"
import Cookies from "universal-cookie";
import NavbarIcon from "./user-solid.svg";
import PageTemplate from "../Templates/PageTemplate";
import SortableHeader from '../Table/TableComponents/SortableHeader';
import CheckboxHeader from "../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../Table/TableComponents/CheckboxCell";
import AddCustomerModal from "./AddCustomers/AddCustomerModal";
import AddCompanyModal from "./AddCustomers/AddCompanyModal";
// import {  } from '@fortawesome/free-regular-svg-icons'

function Customer() {
  const navigate = useNavigate();
  const cookies = new Cookies();
const [loading, setLoading] = useState(true); 
  const [loadingPage, setLoadingPage] = useState(false);
  const [consumer, setConsumer] = useState([]);
  const [searchengine, setSearchengine] = useState("");
  const [searchBarInput, setSearchBarInput] = useState("");
  const [timerId, setTimerId] = useState(null);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({orderBy: '', direction: ''});

  const fetchAll = async () => {
    setLoading(true);
    const [consumerResponse] = await Promise.all([
      middleware.get(`sp/customersTableAll?page=${pagination.page}&page_size=${pagination.page_size}${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&direction=${order.direction}` : ''}`),
    ]);
    setConsumer(consumerResponse.data.data);
    setPagination({...pagination, "max_items": consumerResponse.data.total, "begin_of_page": consumerResponse.data.from, "end_of_page": consumerResponse.data.to})
    setLoading(false);
  };

  useEffect(() => {
    try {
      fetchAll();
    } catch (error) {
      console.warn(error)
    }
  }, [pagination.page_size, pagination.page, order, order.direction]);

  useEffect(() => {
    try {
      if (searchengine.trim() === "" && searchengine === "") {
        // Code to fetch all when searchengine is empty
        fetchAll();
      } else if (searchengine.trim() !== "") {
        // Code to perform the search when searchengine has actual characters
        const doIt = async () => {
          const [fetchConsumer] = await Promise.all([
            middleware.get(
              `sp/customersTableAll?page=${pagination.page}&page_size=${pagination.page_size}&searchTerm=${searchengine}&noSearch=["primary_key","size_id","version","status","updated_at","created_at"]`
            ),
          ]);
          setConsumer(fetchConsumer.data.data);
          setPagination({
            ...pagination,
            max_items: fetchConsumer.data.total,
            begin_of_page: fetchConsumer.data.from,
            end_of_page: fetchConsumer.data.to,
          });
        };
        doIt();
      }
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
}, [searchengine]);

  const searchDelay = async (value) => {
    // const value = await e.target.value
    setSearchBarInput(value)
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      setSearchengine(value);
    }, 500);

    setTimerId(newTimerId);
  };

  const [selectedIds, setSelectedIds] = useState([]);

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    setSelectedIds(previous => 
      isSelected ? [...previous, productId] : previous.filter(id => id !== productId)
    );
  };

  const closeModalCustomer = () => setShowCustomerModal(false)
  const closeModalCompany = () => setShowCompanyModal(false)
  
  // Columns for the table
  const columns = useMemo(
    () => [
        {
          Header: (
            <CheckboxHeader currentItem={consumer} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'consumer_id'}>
            </CheckboxHeader>
          ),
          accessor: "select",
          Cell: ({ row }) => {
            return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'consumer_id'}/>
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} pos={"start"}  field="name">
              Naam
            </SortableHeader>
          ),
          accessor: 'name',
          Cell: ({ row }) => {
            return <a href={`/klanten/bewerken/${row.original.internal_id}`} className="text-info text-decoration-none ">{row.original.name}</a>
          }
        },
        {
          Header: () => (       
            <SortableHeader currentItem={order} setItem={setOrder} pos={"start"}  field="internal_id">
              Klantnummer
            </SortableHeader>
          ),
          accessor: 'internal_id',
          Cell: ({ row }) => {
            return row.original.internal_id
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="email">
              E-mailadres 
            </SortableHeader>
          ),
          accessor: 'email',
          Cell: ({ row }) => {
            return row.original.email
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="unpaid_amount">
              Openstaand saldo
            </SortableHeader>
          ),
          accessor: 'unpaid_amount',
          Cell: ({ row }) => {
            return <div>€ {row.original.unpaid_order_amount}</div>
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field={'internal_id'}>
              Relatie
            </SortableHeader>
          ),
          accessor: 'relation',
          Cell: ({ row }) => {
            if(row.original.internal_id.includes('C-')) { return "Consument" } else { return "Business"}
          }
        },
    ],
    [order.orderBy, order.direction, selectedIds, setSelectedIds]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: consumer })
  
  const Action = (methode) => {
    if (methode == 'Select All') {
      setSelectedIds([...selectedIds, ...consumer.filter(cons => !selectedIds.includes(cons.consumer_id)).map(e => e.consumer_id)])
    }
    else if (methode == 'De-select All') {
      setSelectedIds([])
    }
  }

  return (
    <PageTemplate navbarTitle={"Customer HUB"} pageIsLoading={loadingPage} iconNavbar={NavbarIcon}>
      <div className="CustomerOverview">
        <AddCustomerModal show={showCustomerModal} close={closeModalCustomer} />
        <AddCompanyModal show={showCompanyModal} close={closeModalCompany} />
        <div className="TableCustomers">
          <TablePreset 
            data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'consumer_id'}]} 
            setPagination={setPagination}
            tableLoading={loading}
            handleSelection={handleSelection} 
            searchBar={[{ shown: true, value: searchBarInput, function: searchDelay}]}
            actionOptions={[{title: "Selecteer alles", function: () => Action('Select All')}, {title: "De-selecteer alles", function: () => Action('De-select All')}]} // actionOptions={[{title: "selecteer alles", function: () => console.log(true)}]}
            leftButtons={[{title: "Filteren", function: () => console.log('a')}]} // leftButtons={[{title: "a", function: () => console.log('a')}, {title: "b", function: () => console.log('b')}]} 
            rightButtons={[{title: "Nieuw consument", function: () => setShowCustomerModal(true)}, {title: "Nieuw bedrijf", function: () => setShowCompanyModal(true)}]} // rightButtons={[{title: "c", function: () => console.log('c')}]}
          />
        </div>
      </div>
    </PageTemplate>
  );
}

export default Customer;