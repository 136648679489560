import { toast } from 'react-toastify';

const ToastInfo = (text, time) => {
  return (
    toast.info(text, {
      position: "top-right",
      autoClose: time ? time : 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        top: '100px'
      }
    })
  )
}

export default ToastInfo;