import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import middleware from '../../../Api/Middleware';
import { useParams } from 'react-router';

const RemovePointsModal = ({ openRemovePoints, closeRemovePoints }) => {
  const [showRemovePoints, setShowRemovePoints] = useState(false);
  const [points, setPoints] = useState("");
  const [reason, setReason] = useState("");
  const { internal_id } = useParams();

  useEffect(() => {
    if (openRemovePoints) {
      setShowRemovePoints(true)
    }
  }, [openRemovePoints]);
  
  const closeRemovePointsModal = () => {
    setShowRemovePoints(false);
    closeRemovePoints(true)
  }

  const submitRemovePoints = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }
      // Convert points to a string with a plus sign
      const pointsWithMin = `-${points}`;
      console.log(pointsWithMin);

      await middleware.post("loyaltymutations", {
        internal_id: internal_id,
        amount: pointsWithMin, // Send points with a plus sign
        reason: reason,
      }).then(() => window.location.reload());      
    } catch (err) {
      console.error(err);
    }
  };
  
  return (
    <Modal
        id="modalBlueprint"
        show={showRemovePoints}
        onHide={() => closeRemovePointsModal()}
        
        size="xl"
        centered
        backdrop="static"
      >
        <Modal.Header className="modalTop ps-5 pt-5 justify-content-between">
          <h3>Punten gebruiken / verwijderen</h3>
          <button variant="transparent" className=' btn' onClick={() => closeRemovePointsModal()}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter" style={{height: "300px"}}>
          <div className='col-12'>
            <div className='d-flex flex-column col-4 mb-3'>
              <label className='mb-2  fw-medium'>Punten gebruiken / verwijderen</label>
              <input type='number' min={0} onChange={(e) => setPoints(e.target.value)} className='standardInput px-2' />
            </div>
            <div className='d-flex flex-column col-12 '>
              <label className='mb-2  fw-medium'>Reden</label>
              <select className='standardInput standardDropdownArrow px-2' onChange={(e) => setReason(e.target.value)}>
                <option hidden></option>
                <option>Korting voor dienst</option>
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
          <button className='lightBlueStandardButton' onClick={() => submitRemovePoints()}>Opslaan</button>
        </Modal.Footer>
      </Modal>
  );
}

export default RemovePointsModal;
