import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal } from "react-bootstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import middleware from "../../Api/Middleware";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Customer.css";
import InputComponent from "./InputComponent";
import ToastSuccess from "../../Toasts/ToastSuccess";
import ToastError from "../../Toasts/ToastError";
import Plus from "./Icons/Add.svg";

const AddCustomerModal = ({ show, close }) => {
  const [showCustomer, setShowCustomer] = useState(false);
  const [countryInfo, setCountryInfo] = useState({});
  const [countryIdInfo, setCountryIdInfo] = useState([]);
  const [emails, setEmails] = useState(['']);
  const [phoneNumbers, setPhoneNumbers] = useState(['']);
  const [prefixId, setPrefixId] = useState();
  const [formData, setFormData] = useState({
    newsPaper: false,
    birthday: false,
  });
  const formRef = useRef(null);

  const normalizeLabel = (label) => {
    return label.trim().replace(/[\s\-]+/g, "");
  };

  const getValueInput = useCallback((value, label) => {
    const normalizedLabel = normalizeLabel(label);
    setFormData((prev) => ({ ...prev, [normalizedLabel]: value }));
  }, []);

  const fetchCountry = async () => {
    const [fetchCountries] = await Promise.all([
      middleware.get(`/countryCodes`),
    ]);
    setCountryInfo(fetchCountries.data);
  };


  useEffect(() => {
    try {
      fetchCountry();
    } catch (error) {
      console.warn(error);
    }
  }, [showCustomer]);

  console.log(countryIdInfo);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (countryIdInfo.length <= 0) {
      ToastError("Kies een land.");
      return;
    }

    let consumerCreated = false;
    let consumerId = null;

    try {
      await middleware.post("/addConsumers", {
        first_name: formData.FirstName,
        last_name: formData.LastName,
        country_code_id: countryIdInfo.country_code_id || null,
        emails: emails,
        phone_numbers: phoneNumbers,
        city: formData.City,
        streetname: formData.Street,
        birthday: formData.BirthDate,
        housenumber: formData.HouseNumber,
        addition: formData.Toevoeging === "" ? null : formData.Toevoeging,
        postalcode: formData.PostalCode,
        newsletter: formData.newsPaper === false ? 0 : 1,
        birthday_promotion: formData.birthday === false ? 0 : 1,
        address_kind: 2,
      });

      ToastSuccess("Consument is aangemaakt");
      window.location.reload();
    } catch (err) {
      if (consumerCreated) {
        await middleware.delete(`/consumers/${consumerId}`);
        ToastError("Aanmaken van Consument mislukt");
      } else {
        console.log(err);
        ToastError("Voornaam, Achternaam, Email(minimaal 1), Postcode, Huisnummer, Straat en Stad zijn verplichte velden", 10000);
      }
    }
  };

  console.log(formData);

  useEffect(() => {
    if (show) {
      setShowCustomer(true);
    }
  }, [show]);

  const handleCheckboxChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.checked,
    });
  };

  const closeCustomerModal = () => {
    setShowCustomer(false);
    close(true);
  };

  const handleFormSubmit = (e) => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  const addEmailField = () => {
    if (emails.length < 3) {
      setEmails([...emails, '']);
    } else {
      ToastError("Je kunt maximaal 3 e-mailadressen toevoegen");
    }
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const addPhoneNumberField = () => {
    if (phoneNumbers.length < 3) {
      setPhoneNumbers([...phoneNumbers, '']);
    } else {
      ToastError("Je kunt maximaal 3 telefoonnummers toevoegen");
    }
  };

  const handlePhoneNumberChange = (index, value) => {
    const newPhoneNumbers = [...phoneNumbers];
    newPhoneNumbers[index] = value;
    setPhoneNumbers(newPhoneNumbers);
  };

  return (
    <Modal
      id="modalBlueprint"
      show={showCustomer}
      onHide={() => closeCustomerModal()}
      
      size="xl"
      backdrop="static"
    >
      <Modal.Header className="modalTopCustomers justify-content-between">
        <div className="d-flex flex-row">
          <h3 className="ms-3 text-nowrap me-3">Consument aanmaken</h3>
          <select
            className="form-select inputFieldAddCustomer dropdownsNewCustomers"
            onChange={(e) => setCountryIdInfo(JSON.parse(e.target.value))}
            >
              <option hidden>Kies een land</option>
              {countryInfo?.length > 0 && countryInfo.map(country => (
                <option key={country.country_code_id} value={JSON.stringify(country)}>
                  {country.country}
                </option>
              ))}
            </select>
        </div>
        <button
          variant="transparent"
          className="btn"
          onClick={() => closeCustomerModal()}
        >
          <FontAwesomeIcon icon={faXmark} className="closeModal text-dark" />
        </button>
      </Modal.Header>
      <Modal.Body className="modalCenterCustomers">
        <div className="contendModal w-100">
          <form className="col-12" ref={formRef} onSubmit={handleSubmit}>
            <div className="col-12 d-flex flex-row align-content-end mb-3">
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                alignmentInputs={"justify-content-start"}
                parentCol={6}
                labelName={"Voornaam"}
                labelValue={"FirstName"}
                required={true}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
              <InputComponent
                type={"text"}
                alignmentInputs={"justify-content-end"}
                parentCol={6}
                required={true}
                onValueChange={getValueInput}
                labelName={"Achternaam"}
                labelValue={"LastName"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
            </div>
            <div className="col-12 d-flex flex-row align-content-end my-3">
              <div className="d-flex flex-column col-6 pe-3">
                <div className="d-flex justify-content-between">
                  <label className='fw-semibold mb-2'>Emails</label>
                  <img
                    className='pe-3 addIconSizeModals'
                    src={Plus}
                    onClick={addEmailField}
                    alt="Add"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                {emails.map((email, index) => (
                  <input
                    key={index}
                    className={`inputFieldAddCustomer mb-2 px-3`}
                    type="email"
                    required={true}
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                  />
                ))}
              </div>
              <div className="d-flex flex-column col-6 ps-3">
                <div className="d-flex justify-content-between">
                  <label className='fw-semibold mb-2'>Telefoonnummers</label>
                  <img
                    className='pe-3 addIconSizeModals'
                    src={Plus}
                    onClick={addPhoneNumberField}
                    alt="Add"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                {phoneNumbers.map((phoneNumber, index) => (
                  <input
                    key={index}
                    className={`inputFieldAddCustomer mb-2 px-3`}
                    type="text"
                    required={true}
                    value={phoneNumber}
                    onChange={(e) => handlePhoneNumberChange(index, e.target.value)}
                  />
                ))}
              </div>
            </div>
            <div className="col-12 d-flex flex-row align-content-end my-3">
              <InputComponent
                type={"date"}
                onValueChange={getValueInput}
                parentCol={6}
                labelName={"Geboortedatum"}
                labelValue={"BirthDate"}
                classnamesInput={"col-12 widthInputComponents diffDateIcon px-3"}
              />
              <div className="col-6 d-flex justify-content-start">
                <div className="col-4 d-flex flex-column">
                  <label className="mb-2 ms-4 fw-medium">Nieuwsbrief</label>
                  <label className="switchNewCustomer ms-4">
                    <input
                      type="checkbox"
                      checked={formData.newsPaper}
                      onChange={(e) => handleCheckboxChange(e, "newsPaper")}
                    />
                    <span className="sliderNewCustomer round"></span>
                  </label>
                </div>
                <div className="col-4 d-flex flex-column">
                  <label className="mb-2 ms-4 fw-medium">Verjaardagactie</label>
                  <label className="switchNewCustomer ms-4">
                    <input
                      type="checkbox"
                      checked={formData.birthday}
                      onChange={(e) => handleCheckboxChange(e, "birthday")}
                    />
                    <span className="sliderNewCustomer round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex flex-row align-content-end my-3">
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                parentCol={6}
                labelName={"Postcode"}
                labelValue={"PostalCode"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
              <div className="col-6 d-flex justify-content-end">
                <div className="col-12 d-flex widthInputComponents flex-row justify-content-end">
                  <InputComponent
                    alignmentInputs={"justify-content-start"}
                    onValueChange={getValueInput}
                    type={"number"}
                    parentCol={6}
                    labelValue={"HouseNumber"}
                    labelName={"Huisnummer"}
                    classnamesInput={"col-12 widthInputComponents px-3"}
                  />
                  <InputComponent
                    alignmentInputs={"justify-content-end"}
                    onValueChange={getValueInput}
                    type={"text"}
                    parentCol={6}
                    labelValue={"Addition"}
                    labelName={"Toevoeging"}
                    classnamesInput={"col-12 widthInputComponents px-3"}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 d-flex flex-row align-content-end my-3">
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                alignmentInputs={"justify-content-start"}
                parentCol={6}
                labelValue={"Street"}
                labelName={"Straat"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                alignmentInputs={"justify-content-end"}
                parentCol={6}
                labelName={"Stad"}
                labelValue={"City"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottomCustomers">
        <button
          type="button"
          className="btn addButtonCustomer px-4 mb-4 rounded-pill"
          onClick={handleFormSubmit}
        >
          Consument aanmaken
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCustomerModal;
