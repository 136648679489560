import React, { useState, useEffect } from "react";
import middleware from "../Api/Middleware";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import "../Main/Main.css";
import "./ColorSettings.css";
import Loading from "../LoadingScreen/LoadingScreen";
import NavbarIcon from "../WarehouseSettings/Icons/paint-palette.png"
import TableDrag from "../Table/TableDrag/TableDrag";
import { Modal } from "react-bootstrap";
import ModalColorAdd from "../Products/subpages/sub_components/VariationModals";
import PageTemplate from "../Templates/PageTemplate";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ColorSettings() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [show, setShow] = useState(false);
  const [selectedColorIds, setSelectedColorIds] = useState([]); // usestate for the selected checkboxes
  const [loading, setLoading] = useState(true); // useState for page loading
  const [loadingPage, setLoadingPage] = useState(false);
  const [closeModal, setCloseModal] = useState();
  const [addNewColor, setAddNewColor] = useState(false);
  const [editColor, setEditColor] = useState();  
  const [searchengine, setSearchengine] = useState("");
  const [timerId, setTimerId] = useState(null);
  const [searchBarInput, setSearchBarInput] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(true); // useState for the Loadingscreen
  const [colors, setColors] = useState([]); // useState for all products in the database
  const [showBlueprintModal, setShowBlueprintModal] = useState(false);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table

  // Sizes usetates

  // handles to close the de-activate modal
  const handleClose = () => setShow(false);

  // fetched all the products and the stock an the colors from the database
  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchColors] = await Promise.all([
        middleware.get(
          `sp/colorsTableAll?page=${pagination.page}&page_size=${pagination.page_size}&orderBy=sorting_order`
        ),
      ]);
      console.log(fetchColors);
      const updatedColorsOrder = fetchColors.data.data.map((item, index) => ({
        ...item,
        sorting_order:
          index +
          (pagination.page === 1
            ? 0
            : pagination.page_size * (pagination.page - 1)) +
          1, // updating the sorting order based on position
      }));

      await middleware.put(`products/colors`, {
        bulk_data: updatedColorsOrder,
      });

      setColors(updatedColorsOrder);
      setPagination({
        ...pagination,
        max_items: fetchColors.data.total,
        begin_of_page: fetchColors.data.from,
        end_of_page: fetchColors.data.to,
      }); // sets the pagination usestate with the max items begin of the page and the end of the page
      setLoadingScreenFunc();
    } catch (error) {
      setLoading(false)
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };
  // function that does an api call to only get the colors the user searched for and updates whenever the colorsearchengine changes
  useEffect(() => {

    try {
      if (searchengine.trim() === "" && searchengine === "") {
        // Code to fetch all when searchengine is empty
        fetchAll();
      } else if (searchengine.trim() !== "") {
        // Code to perform the search when searchengine has actual characters
        const doIt = async () => {
          const [fetchColors] = await Promise.all([
            middleware.get(
              `sp/colorsTableAll?page=${pagination.page}&filter=status=0&page_size=${pagination.page_size}&searchTerm=${searchengine}&noSearch=[primary_key,color_id,version,status,updated_at,created_at]`
            ),
          ]);
          setColors(fetchColors.data.data);
          setPagination({
            ...pagination,
            max_items: fetchColors.data.total,
            begin_of_page: fetchColors.data.from,
            end_of_page: fetchColors.data.to,
          });
        };
        doIt();
      }
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
}, [searchengine]);

  const searchDelay = async (value) => {
    // const value = await e.target.value
    setSearchBarInput(value)
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      setSearchengine(value);
    }, 500);

    setTimerId(newTimerId);
  };

  // useEffect that fires every time the pagination.page_size or pagination.page changes and on every fire it uses the fetchall function
  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page]);

  // function that first sets the set loading to fals and than setloading screen to give everything time to load in
  function setLoadingScreenFunc() {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }

  // a function that delays something for 0,5sec
  function delayedFunction(Func) {
    setTimeout(() => {
      Func();
    }, 500);
  }

  // function that suspends colors the are selected
  const suspend = async () => {
    // Iterate over each product id in the selectedColorIds array
    let bulkData = [];
    selectedColorIds.map(async (data) => {
      bulkData.push({
        primary_key: data,
        status: 1,
        sorting_order: null,
      });
    });

    await middleware.put("products/colors", {
      bulk_data: bulkData,
    });

    // After updating all products, call fetchAll function to get updated list of products
    await fetchAll();
  };
  
  const openColorModal = (modal) => {
    if (modal == "kleur") {
      setAddNewColor(true);
    }
  };

  // const handleModal1Open = () => setShowBlueprintModal(true);

  // closes the blueprintModal
  const closeBlueprintModal = () => {
    setShowBlueprintModal(false);
  };


  // if (loading) {
  //   return (
  //     <div
  //       className={`loading-container ${
  //         loadingScreen ? "fade-in" : "fade-out"
  //       }`}
  //     >
  //       <Loading />
  //     </div>
  //   );
  // }

  return (
    <>
      <ModalColorAdd
        handleClose={closeBlueprintModal}
        openEditModal={showBlueprintModal}
        editColor={editColor}
        callColors={false}
        closeEditModal={(e) => setCloseModal(e)}
        openAddColor={(e) => setAddNewColor(e)}
        addNewColor={addNewColor}
        redirect={true}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Let op!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          U gaat {selectedColorIds.length}{" "}
          {selectedColorIds.length === 1 ? "item" : "items"} de-activeren. Weet
          U dat zeker?
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn bg-danger text-white" onClick={handleClose}>
            Annuleren
          </button>
          <button
            className="btn bg-success text-white"
            onClick={() => {
              handleClose();
              delayedFunction(suspend);
              setSelectedColorIds([]);
            }}
          >
            Ik weet het zeker
          </button>
        </Modal.Footer>
      </Modal>
      <PageTemplate navbarTitle={"Kleuren"} iconNavbar={NavbarIcon}>
        <div className="w-100">
          <div className="d-flex justify-content-between flex-column mb-3 ">
            <div className="d-flex flex-row mb-3">
              <button
                className="btn bg-white fw-semibold rounded-pill"
                onClick={() => navigate("/warehouse/instellingen")}
              >
                  <FontAwesomeIcon icon={faChevronLeft}/>
              </button>
              <h3 className="mb-0 ms-2 text-center d-flex align-items-center">
                Kleuren instellingen
              </h3>
            </div>
            <div className=" d-flex justify-content-start w-100 flex-wrap">
              <div className="colorSettings">
                <TableDrag
                  dataParameter={colors}
                  tableLoading={loading}
                  pagination={pagination}
                  handlePaginationChange={(event) => setPagination(event)}
                  primaryKey={"primary_key"}
                  urlEndPoint={"products/colors"}
                  editColor={(e) => setEditColor(e)}
                  openCorrectModal={(e) => setShowBlueprintModal(e)}
                  closeCorrectModal={closeModal}
                  openModal={(e) => openColorModal(e)}
                  newItemTitle={"kleur"}
                  dataEditModalSize={() => {}}
                  searchTerm={(e) => searchDelay(e)}
                  closeEditModalSize={() => {}}
                  columns={[
                    { key: "sorting_order", title: "Sorteer volgorde" },
                    { key: "name", title: "Naam" },
                    {
                      key: "color_preview", // Verander de sleutel naar iets unieks
                      title: "Kleur",
                      customRenderer: (
                        row // De 'row' parameter zal het hele rijobject bevatten
                      ) => (
                        <div
                          style={{
                            width: "100%",
                            height: "20px",
                            backgroundColor: row.hex, // Hier gebruiken we de hex-waarde van de rij
                            border: "1px solid #000",
                          }}
                        ></div>
                      ),
                    },
                    { key: "hex", title: "Hex" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        
      </PageTemplate>
    </>
  );
}

export default ColorSettings;
