import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from 'react-router';
import middleware from '../../../Api/Middleware';

const AddPointsModal = ({ openAddPoints, closeAddPoints }) => {
  const [showAddPoints, setShowAddPoints] = useState(false);
  const [points, setPoints] = useState("");
  const [reason, setReason] = useState("");
  const { internal_id } = useParams();

  useEffect(() => {
    if (openAddPoints) {
      setShowAddPoints(true);
    }
  }, [openAddPoints]);
  
  const closeAddPointsModal = () => {
    setShowAddPoints(false);
    closeAddPoints(true);
  }

  const submitNewAddPoints = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }

      // Convert points to a string with a plus sign
      const pointsWithPlus = `+${points}`;

      await middleware.post("loyaltymutations", {
        internal_id: internal_id,
        amount: pointsWithPlus, // Send points with a plus sign
        reason: reason,
      }).then(() => window.location.reload());      
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      id="modalBlueprint"
      show={showAddPoints}
      onHide={() => closeAddPointsModal()}
      className="colormodals"
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header className="modalTop ps-5 pt-5 justify-content-between">
        <h3>Punten toevoegen</h3>
        <button variant="transparent" className=' btn' onClick={() => closeAddPointsModal()}>
          <FontAwesomeIcon icon={faXmark} className="closeModal" />
        </button>
      </Modal.Header>
      <Modal.Body className="modalCenter" style={{height: "300px"}}>
        <div className='col-12'>
          <div className='d-flex flex-column col-4 mb-3'>
            <label className='mb-2 fw-medium'>Punten toevoegen</label>
            <div className='d-flex align-items-center'>
              <input 
                type='number' 
                min={0} 
                value={points} 
                onChange={(e) => setPoints(e.target.value)} 
                className='standardInput px-2' 
              />
            </div>
          </div>
          <div className='d-flex flex-column col-12'>
            <label className='mb-2 fw-medium'>Reden</label>
            <select className='standardInput standardDropdownArrow px-2' onChange={(e) => setReason(e.target.value)}>
              <option hidden></option>
              <option>Item verkocht</option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottom">
        <button className='lightBlueStandardButton' onClick={() => submitNewAddPoints()}>Opslaan</button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPointsModal;
