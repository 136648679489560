import React, { useState, useEffect } from 'react';
import Plus from "./Icons/Add.svg";

const InputComponent = ({
  labelName,
  labelValue,
  defaultValue,
  type,
  placeholder,
  required,
  classnamesInput,
  parentCol,
  classnamesParent,
  alignmentInputs,
  onValueChange,
  onBlur, // Add onBlur prop
  max,
  min
}) => {
  const [valueInput, setvalueInput] = useState(defaultValue || ""); // Initialize with defaultValue

  const today = new Date().toISOString().split('T')[0];

  const handleChange = (e) => {

    setvalueInput(e.target.value);
    if (onValueChange) {
      onValueChange(e.target.value, labelValue);
    }
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e.target.value, labelValue);
    }
  };

  return (
    <div className={`d-flex flex-column col-${parentCol} ${classnamesParent || ""}`}>
      <div className={`d-flex flex-column`}>
        <div className={`d-flex w-100 ${alignmentInputs ?? "justify-content-start"}`}>
          <div className='ps-1 mb-2 widthInputComponents d-flex flex-row justify-content-between'>
            <label className='fw-semibold'>{labelName}</label>
          </div>
        </div>
        <div className={`d-flex w-100 ${alignmentInputs ?? "justify-content-start"}`}>
          <input 
            className={`inputFieldAddCustomer ${classnamesInput}`}
            type={type}
            placeholder={placeholder}
            required={required}
            defaultValue={defaultValue} // Controlled input
            max={type === 'date' ? today : type === 'number' ? max : ""}
            min={min}
            onChange={handleChange} // Call handleChange on change
            onBlur={handleBlur} // Call handleBlur on blur
          />
        </div>
      </div>
    </div>
  );
};

export default InputComponent;
