import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import warning from "../Icons/Warning-sign.svg";

const QuotationModal = ({ openQuotation, closeQuotation, rowToDelete, onDeleteConfirmed }) => {
  const [showQuotation, setShowQuotation] = useState(false);

  useEffect(() => {
    if (openQuotation) {
      setShowQuotation(true);
    }
  }, [openQuotation]);

  const closeQuotationModal = () => {
    setShowQuotation(false);
    closeQuotation();
  }

  const handleDelete = async () => {
    if (onDeleteConfirmed) {
      await onDeleteConfirmed(); // Call the deletion function passed in as a prop
    }
    closeQuotationModal(); // Close the modal after deletion
  }

  return (
    <Modal
        id="modalBlueprint"
        show={showQuotation}
        onHide={closeQuotationModal}
        className="colormodals"
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-end" style={{height: "60px"}}>
        </Modal.Header>
        <Modal.Body className="modalCenter d-flex justify-content-center">
          <div className='d-flex flex-column justify-content-center'>
            <img style={{width: "75px" }} className=' d-flex align-self-center mb-4' src={warning} alt="Warning" />
            <div className='w-100 d-flex justify-content-center mb-3'>
              <h3 style={{width: "60%"}} className='text-center'>Weet je zeker dat je deze offerte "{rowToDelete?.offer_number}" wilt verwijderen?</h3>
            </div>
            <div className='d-flex justify-content-between align-self-center' style={{width: "60%"}}>
              <button className='btn DarkStandardButton' onClick={closeQuotationModal}>Annuleren</button>
              <button style={{padding: "5px 15px"}} className='btn lightBlueStandardButton' onClick={handleDelete}>Ja, ik weet het zeker</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
        </Modal.Footer>
      </Modal>
  );
}

export default QuotationModal;
