import React, { useMemo, useState, useEffect } from "react";
import Sidebar from "../Navbars/Sidebar/Sidebar";
import Cookies from "universal-cookie";
import Navbar from "../Navbars/Navbar/Navbar";
import middleware from "../Api/Middleware";
import "./Orders.css";
import NavbarIcon from "../Navbars/Sidebar/Icons/envelope.png"
import "../Table/Table.css";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faX,
  faPenToSquare,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../LoadingScreen/LoadingScreen";
import info from "./info.svg";
import saleschannels from "../Api/SalesChannels";
import PageTemplate from "../Templates/PageTemplate";
import Backbutton from "../Products/subpages/images/BackIcon.svg"
import OrderRowEdit from "./OrderRowEdit";


const OrdersNew = () => {
  const { order_id } = useParams();

  const cookies = new Cookies();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);
  const [consumers, setConsumers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [internalCodes, setInternalCodes] = useState([]);
  const [orderConcept, setOrderConcept] = useState([]);
  const [orderInfo, setOrderInfo] = useState([]);
  const [order, setOrder] = useState([]);
  const [allSaleschannels, setAllSaleschannels] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [previousOrder, setPreviousOrder] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [showOrderRow, setShowOrderRow] = useState(false);
  const [previousCustomer, setPreviousCustomer] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [activeSaleType, setActiveSaleType] = useState(2);
  const [activeSaleTypeProduct, setActiveSaleTypeProduct] = useState(2);
  const [activeSaleTypeOrder, setActiveSaleTypeOrder] = useState(2);

  const [productsSearchengine, setProductsSearchengine] = useState("");
  const [consumersSearchengine, setConsumersSearchengine] = useState("");
  const [businessesSearchengine, setBusinessesSearchengine] = useState("");
  const [internalCodesSearchengine, setInternalCodesSearchengine] =
    useState("");

  const [wholeSale, setWholeSale] = useState({
    sale_amount: 0,
    sale_unit: "%",
  });
  const [timerId, setTimerId] = useState(null);
  const [editAllowed, setEditAllowed] = useState(true);
  const [btwState, setBtwState] = useState("incl");
  const [inputChangeProduct, setInputChangeProduct] = useState("");
  const [editingState, setEditingState] = useState(false);
  const [inputChangeInternalCode, setInputChangeInternalCode] = useState("");

  const [submittedButton, setSubmittedButton] = useState("");

  const fetchAll = async () => {
    try {
      const [
        productsResponse,
        consumersResponse,
        businessesResponse,
        saleschannelsResponse,
        shippingmethodsResponse,
        internalCodesResponse,
        ordersResponse,
        addressResponse,
      ] = await Promise.all([
        middleware.get(`products`),
        order_id ? middleware.get(`consumers?status=0`) : null,
        order_id ? middleware.get(`businesses?status=0`) : null,
        saleschannels.get(`saleschannels`),
        saleschannels.get(`saleschannels/shippingmethods`),
        middleware.get(`internalcodes`),
        middleware.get(`orders`),
        middleware.get(`addresses`),
      ]);

      setProducts(productsResponse.data);
      setConsumers(order_id ? consumersResponse.data : []);
      setBusinesses(order_id ? businessesResponse.data : []);
      setAllSaleschannels(saleschannelsResponse.data);
      setShippingMethods(shippingmethodsResponse.data);
      setInternalCodes(internalCodesResponse.data);
      setAllOrders(ordersResponse.data);
      setAddresses(addressResponse.data);
      setOrder(
        ordersResponse.data.filter(
          (filter) => filter.order_id == order_id
        )[0]
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      } else {
        // Handle other types of errors
        console.error("Error occurred:", error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (order_id) {
        const [orderResponse] = await Promise.all([
          middleware.get(`orders?order_id=${order_id}`),
        ]);
        setPreviousOrder(orderResponse.data);
        const order = orderResponse.data;

        if (order.internal_id.charAt(0) == "C") {
          const [customerResponse] = await Promise.all([
            middleware.get(
              `consumers?internal_id=${order.internal_id}&status=0`
            ),
          ]);
          setCustomer(...customerResponse.data);
          setPreviousCustomer(...customerResponse.data);
        } else if (order.internal_id.charAt(0) == "B") {
          const [customerResponse] = await Promise.all([
            middleware.get(
              `businesses?internal_id=${order.internal_id}&status=0`
            ),
          ]);
          setCustomer(...customerResponse.data);
          setPreviousCustomer(...customerResponse.data);
        }

        const [ordersSoldProductsResponse] = await Promise.all([
          middleware.get(`orders/soldproducts?order_id=${order.order_id}`),
        ]);
        setOrderConcept(
          ordersSoldProductsResponse.data.map((e) => ({
            ...e,
            product_id: e.sold_product_id,
            sale_amount: e.sale_amount,
            price_excl_tax: (e.price_incl_tax / 100) * (100 - e.btw_rate),
            sale_unit: e.sale_unit == 0 ? "%" : "€",
          }))
        );
      }
      setLoading(false);
    })();
  }, []);

  const updateSpecificCustomerData = (column, event) => {
    if (customer.internal_id.charAt(0) == "C") {
      if (event.target.value !== previousCustomer[column]) {
        middleware
          .put(`consumers`, {
            ...customer,
            consumer_id: customer.consumer_id,
            [column]: event.target.value,
          })
          .then(
            (res) =>
              successToast(
                `Klant ${event.target.name} bijgewerkt van ${previousCustomer[column]} naar ${event.target.value}`
              ) + setPreviousCustomer(res.data)
          );
      }
    } else if (customer.internal_id.charAt(0) == "B") {
      if (event.target.value !== previousCustomer[column]) {
        middleware
          .put(`businesses`, {
            ...customer,
            consumer_id: customer.consumer_id,
            [column]: event.target.value,
          })
          .then(
            (res) =>
              successToast(
                `Klant ${event.target.name} bijgewerkt van ${previousCustomer[column]} naar ${event.target.value}`
              ) + setPreviousCustomer(res.data)
          );
      }
    }
  };

  const updateSpecificProductRow = (column, sold_product_id) => {
    middleware
      .put(`orders/soldproducts`, {})
      .then(
        (res) =>
          successToast(`Bestelling gegevens bijgewerkt`) +
          setPreviousOrder(res.data)
      );
  };

  const successToast = (text) => {
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const dangerToast = (text) => {
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const NewOrder = async (e) => {
    e.preventDefault();
    if (orderConcept.length > 0) {
      const currentDate = new Date();
      const year = currentDate.getFullYear() % 100;
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const hour = String(currentDate.getHours()).padStart(2, "0");
      const minute = String(currentDate.getMinutes()).padStart(2, "0");
      const second = String(currentDate.getSeconds()).padStart(2, "0");
      let orderNumber = "";
      if (allOrders.length > 0) {
        const lastRow = allOrders.length - 1;
        const rest = Number(allOrders[lastRow]["order_number"].substring(6));
        const incrementedRest = (rest + 1).toString(10);
        const leadingZeros = "000000".substring(0, 6 - incrementedRest.length); // Pad with leading zeros
        orderNumber = `TDS-${year}${leadingZeros}${incrementedRest}`;
      } else {
        orderNumber = `TDS-${year}000000`;
      }
      switch (submittedButton) {
        case "asConcept":
          console.log("asConcept");
          middleware
            .post("orders", {
              internal_id: customer.internal_id,
              order_number: orderNumber,
              order_status: 1,
              note: e.target.note.value,
              sales_channel_id: e.target.sales_channel_id.value,
              payment_status: 0,
              delivery_status: 0,
              reference: e.target.reference.value,
            })
            .then((resp) =>
              middleware.post("orders/soldproducts", {
                bulk_data: orderConcept.map((concept) => ({
                  order_id: resp.data.order_id,
                  product_id: concept.product_id,
                  product_name: concept.product_name,
                  amount: concept.amount,
                  price_incl_tax: concept.price_incl_tax,
                  product_number: concept.product_number,
                  product_description: concept.product_description,
                  btw_rate: concept.btw_rate,
                  internal_id: customer.internal_id,
                  sold_at: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
                  sale_amount: concept.sale_amount,
                  sale_unit: concept.sale_unit == "%" ? 0 : 1,
                })),
              })
            )
            .then(
              (e) =>
                successToast("Bestelling " + orderNumber + " aangemaakt") +
                dangerToast("Nog geen factuur verstuurd voor " + orderNumber) +
                navigate("/bestellingen")
            );
          break;
        case "withInvoice":
          middleware
            .post("orders", {
              internal_id: customer.internal_id,
              order_number: orderNumber,
              order_status: 2,
              note: e.target.note.value,
              sales_channel_id: e.target.sales_channel_id.value,
              payment_status: 0,
              delivery_status: 0,
              reference: e.target.reference.value,
            })
            .then((resp) =>
              middleware
                .post("orders/soldproducts", {
                  bulk_data: orderConcept.map((concept) => ({
                    order_id: resp.data.order_id,
                    product_id: concept.product_id,
                    product_name: concept.product_name,
                    amount: concept.amount,
                    price_incl_tax: concept.price_incl_tax,
                    product_number: concept.product_number,
                    product_description: concept.product_description,
                    btw_rate: concept.btw_rate,
                    internal_id: customer.internal_id,
                    sold_at: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
                    sale_amount: concept.sale_amount,
                    sale_unit: concept.sale_unit == "%" ? 0 : 1,
                  })),
                })
                .then((response) => console.log(response))
            )
            .then(
              (e) =>
                successToast("Bestelling " + orderNumber + " aangemaakt") +
                successToast("Factuur verstuurd voor " + orderNumber) +
                navigate("/bestellingen")
            );
          break;
        case "withInvoiceAfterwards":
          middleware
            .put("orders", {
              order_id: order_id,
              order_status: 2,
            })
            .then(
              (e) =>
                successToast("Factuur verstuurd voor " + orderNumber) +
                navigate("/bestellingen")
            )
            .catch((e) => console.warn(e));
          break;
      }
    }
  };

  const searchDelay = async (value, setSearchEngine, setInput) => {
    // const value = await e.target.value
    setInput(value);
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      setSearchEngine(value);
    }, 500);

    setTimerId(newTimerId);
  };

  const funcDelay = async (func, column, event) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      func(column, event);
    }, 500);

    setTimerId(newTimerId);
  };

  useEffect(() => {
    if (consumersSearchengine !== "") {
      const doIt = async () => {
        const [response] = await Promise.all([
          middleware.get(`consumers?searchTerm=${consumersSearchengine}`),
        ]);
        setConsumers(response.data);
      };
      doIt();
    }
  }, [consumersSearchengine]);

  useEffect(() => {
    if (businessesSearchengine !== "") {
      const doIt = async () => {
        const [response] = await Promise.all([
          middleware.get(`businesses?searchTerm=${businessesSearchengine}`),
        ]);
        if (response.status == 200) {
          setBusinesses(response.data);
        }
      };
      doIt();
    }
  }, [businessesSearchengine]);

  useEffect(() => {
    if (productsSearchengine !== "") {
      const doIt = async () => {
        const [response] = await Promise.all([
          middleware.get(`products?searchTerm=${productsSearchengine}`),
        ]);
        if (response.status == 200) {
          setProducts(response.data);
        }
      };
      doIt();
    }
  }, [productsSearchengine]);

  if (loading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <PageTemplate navbarTitle={"Nieuwe bestelling"} iconNavbar={NavbarIcon}>
      <OrderRowEdit openOrderRow={showOrderRow} closeOrderRow={() => setShowOrderRow(false)}/>
      <div className="NewOrder w-100">
        <form onSubmit={NewOrder}>
          <div>
            <div className="d-flex flex-row mb-4 justify-content-between">
              <div className='d-flex flex-row align-items-center' onClick={() => navigate("/bestellingen")}>
                <img style={{width: "25px"}} className='me-3' src={Backbutton}/>
                <p className='mb-0 fw-semibold'>Alle bestellingen</p>
              </div>
              <div>
                <button className="darkBlueStandardButton me-2 px-4 py-2">Maak factuur aan</button>
                <button className="lightBlueStandardButton px-4 py-2">Sla op als concept</button>
              </div>
            </div>
            
            <div className="d-flex justify-content-between gap-5 parentBoxes flex-column w-100">
              <div className="bg-white rounded-4 p-4 customerBox">
                <div className="d-flex justify-content-between px-3">
                  <h5 className=" fw-semibold ">
                    Klant{" "}
                    {order_id ? (
                      <span
                        role="button"
                        className="ps-2"
                        onClick={(e) => setEditingState(!editingState)}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>
                    ) : (
                      ""
                    )}
                  </h5>
                  {order_id ? (
                    ""
                  ) : customer.internal_id !== "C-0001" ? (
                    <div>
                      {/* <span
                        role="button"
                        className="text-primary"
                        onClick={async (e) => {
                          try {
                            const response = await middleware.get(
                              `consumers?internal_id=C-0001`
                            );
                            const anonymouscustomer = response.data[0]; // Assuming there is only one customer with internal_id "C-0001"
                            setCustomer(anonymouscustomer);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      >
                        Anonieme bestelling?
                      </span> */}

                      <button className="darkBlueStandardButton me-2 py-2">Nieuwe consument</button>
                      <button className="lightBlueStandardButton py-2">Nieuw bedrijf</button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="customersSearch px-3 mb-3">
                  {editingState || !order_id ? (
                    <>
                      <label className=" fw-semibold mb-2">Klantnaam</label>
                      <span className="borderedSearchStyle d-flex align">
                        <input
                          className={!consumersSearchengine ? "w-100 clientSearch SearchIcon standardInput p-2" : "w-100 p-2 clientSearching SearchIcon standardInput"}
                          placeholder="Zoek klant op naam, email etc."
                          type="text"
                          value={
                            inputChangeInternalCode
                              ? inputChangeInternalCode
                              : ""
                          }
                          onChange={(e) =>
                            searchDelay(
                              e.target.value,
                              setConsumersSearchengine,
                              setInputChangeInternalCode
                            ) +
                            searchDelay(
                              e.target.value,
                              setBusinessesSearchengine,
                              setInputChangeInternalCode
                            )
                          }
                        ></input>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                  <table
                    style={{ position: "absolute", zIndex: "5" }}
                    className={
                      !consumersSearchengine ? "d-none" : "customersResults"
                    }
                  >
                    <tbody>
                      {!order_id ? (
                        <>
                          {consumers
                            .filter(
                              (filter) => filter.internal_id !== "C-0001"
                            )
                            .map((data) => (
                              <tr
                                className="customerTableRow"
                                key={data.internal_id}
                                onClick={(e) =>
                                  setCustomer(data) +
                                  setConsumersSearchengine("") +
                                  setBusinessesSearchengine("") +
                                  setInputChangeInternalCode("") +
                                  successToast("Klant geselecteerd")
                                }
                              >
                                <td>
                                  {data.first_name +
                                    " " +
                                    (data.last_name || "")}
                                </td>
                                <td>{data.email}</td>
                                <td>{data.phone_number}</td>
                              </tr>
                            ))}
                          {businesses
                            .filter(
                              (filter) => filter.internal_id !== "C-0001"
                            )
                            .map((data) => (
                              <tr
                                className="customerTableRow"
                                key={data.internal_id}
                                onClick={(e) =>
                                  setCustomer(data) +
                                  setConsumersSearchengine("") +
                                  setBusinessesSearchengine("") +
                                  setInputChangeInternalCode("") +
                                  successToast("Klant geselecteerd")
                                }
                              >
                                <td>{data.company_name}</td>
                                <td>{data.email}</td>
                                <td>{data.phone_number}</td>
                              </tr>
                            ))}
                        </>
                      ) : (
                        <>
                          {consumers
                            .filter(
                              (filter) => filter.internal_id !== "C-0001"
                            )
                            .map((data) => (
                              <tr
                                className="customerTableRow"
                                key={data.internal_id}
                                onClick={(e) =>
                                  middleware.put(`orders`, {
                                    order_id: order.order_id,
                                    internal_id: data.internal_id,
                                  }) +
                                  setCustomer(data) +
                                  setConsumersSearchengine("") +
                                  setBusinessesSearchengine("") +
                                  setInputChangeInternalCode("") +
                                  successToast("Klant aangepast")
                                }
                              >
                                <td>
                                  {data.first_name +
                                    " " +
                                    (data.last_name || "")}
                                </td>
                                <td>{data.email}</td>
                                <td>{data.phone_number}</td>
                              </tr>
                            ))}
                          {businesses
                            .filter(
                              (filter) => filter.internal_id !== "C-0001"
                            )
                            .map((data) => (
                              <tr
                                className="customerTableRow"
                                key={data.internal_id}
                                onClick={(e) =>
                                  middleware.put(`orders`, {
                                    order_id: order.order_id,
                                    internal_id: data.internal_id,
                                  }) +
                                  setCustomer(data) +
                                  setConsumersSearchengine("") +
                                  setBusinessesSearchengine("") +
                                  setInputChangeInternalCode("") +
                                  successToast("Klant aangepast")
                                }
                              >
                                <td>{data.company_name}</td>
                                <td>{data.email}</td>
                                <td>{data.phone_number}</td>
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                {customer.length == 0 ||
                customer.internal_id == "C-0001" ? (
                  customer && customer.internal_id == "C-0001" ? (
                    <div className="mt-2">Deze bestelling anoniem</div>
                  ) : (
                    ""
                  )
                ) : !editingState ? (
                  customer && (
                    <table className="mt-2 w-100 table">
                      <tbody>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "B"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Bedrijfsnaam:</td>
                          <td>
                            {customer.company_name
                              ? customer.company_name
                              : ""}
                          </td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "C"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Naam:</td>
                          <td>
                            {customer.first_name ? customer.first_name : ""}{" "}
                            {customer.last_name ? customer.last_name : ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Telefoonnummer:</td>
                          <td>
                            {customer.phone_number
                              ? customer.phone_number
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Contact e-mail:</td>
                          <td>{customer.email ? customer.email : ""}</td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "B"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Factuur mail:</td>
                          <td>
                            {customer.invoicemail
                              ? customer.invoicemail
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Fax nummer:</td>
                          <td>
                            {customer.fax_number ? customer.fax_number : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )
                ) : (
                  customer && (
                    <table className="mt-2 w-100 table">
                      <tbody>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "B"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Bedrijfsnaam:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData(
                                  "company_name",
                                  e
                                )
                              }
                              name="bedrijfs naam"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.company_name
                                  ? customer.company_name
                                  : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  company_name: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "C"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Voornaam:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData("first_name", e)
                              }
                              name="voornaam"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.first_name
                                  ? customer.first_name
                                  : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  first_name: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "C"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Tussenvoegsel:</td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "C"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Achternaam:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData("last_name", e)
                              }
                              name="achternaam"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.last_name ? customer.last_name : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  last_name: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Telefoonnummer:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData(
                                  "phone_number",
                                  e
                                )
                              }
                              name="telefoonnummer"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.phone_number
                                  ? customer.phone_number
                                  : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  phone_number: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Contact e-mail:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData("email", e)
                              }
                              name="email"
                              className="borderedSearchStyle w-100"
                              value={customer.email ? customer.email : ""}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  email: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "B"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Factuur mail:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData("invoicemail", e)
                              }
                              name="factuur email"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.invoicemail
                                  ? customer.invoicemail
                                  : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  invoicemail: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Fax nummer:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData("fax_number", e)
                              }
                              name="fax nummer"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.fax_number
                                  ? customer.fax_number
                                  : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  fax_number: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )
                )}
              </div>
              <div className="bg-white rounded-4 p-4 w-100">
                <div className="d-flex justify-content-between flex-row flex-wrap mb-2 px-3">
                  <h5 className="fw-semibold">Producten</h5>
                </div>
                <div className="productsSearch px-3">
                  <span
                    className={`borderedSearchStyle d-flex align ${
                      order_id ? "d-none" : ""
                    }`}
                  >
                    <input
                      onChange={(e) =>
                        searchDelay(
                          e.target.value,
                          setProductsSearchengine,
                          setInputChangeProduct
                        )
                      }
                      value={inputChangeProduct}
                      className="border-0 w-100 SearchIcon standardInput px-3"
                      placeholder="Zoek product op naam, prijs etc. Selecteer dan het gewenste product"
                      type="text"
                    ></input>
                  </span>
                  {!productsSearchengine ? (
                    ""
                  ) : (
                    <div className="d-flex w-100 justify-content-center align-content-around searchResults productsResults">
                      <table
                        className="tableSearch"
                        style={{ position: "absolute", zIndex: "5" }}
                      >
                        <tbody className="bg-white">
                          {products.map((product, index) => (
                            <tr
                              key={index}
                              className="tableRow"
                              onClick={() => {
                                const updateOrderConcept = () => {
                                  const existingProductIndex =
                                    orderConcept.findIndex(
                                      (item) =>
                                        item.product_id ===
                                        product.product_id
                                    );

                                  if (existingProductIndex !== -1) {
                                    const updatedOrderConcept = [
                                      ...orderConcept,
                                    ];
                                    updatedOrderConcept[
                                      existingProductIndex
                                    ].amount =
                                      Number(
                                        updatedOrderConcept[
                                          existingProductIndex
                                        ].amount
                                      ) + 1;
                                    setOrderConcept(updatedOrderConcept);
                                  } else {
                                    setOrderConcept([
                                      ...orderConcept,
                                      {
                                        ...product,
                                        amount: 1,
                                        sale_unit: "%",
                                        sale_amount: 0,
                                        price_excl_tax:
                                          (product.price_incl_tax / 100) *
                                          (100 - product.btw_rate),
                                      },
                                    ]);
                                  }
                                };
                                updateOrderConcept();
                                setProductsSearchengine("");
                                setInputChangeProduct("");
                              }}
                            >
                              <td>{product.product_name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center px-3 mt-4 w-100">
                  {orderConcept.length == 0 ? (
                    ""
                  ) : (
                    <div className="d-flex flex-column w-100 mb-3">
                      <table className="w-100 m-0 mb-3 table tableProductSearch">
                        <thead>
                          <tr className="bg-white text-center text-nowrap">
                            <th className=" px-3">Aantal</th>
                            <th className="text-start  px-3">Product</th>
                            <th>
                              Prijs{" "}
                              {btwState.charAt(0).toUpperCase() +
                                btwState.slice(1)}
                              . BTW
                            </th>
                            <th className="text-center">Korting</th>
                            <th>Kortingprijs</th>
                            <th>Totaal</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderConcept.map((product, index) => (
                            <tr key={index} className="text-center" style={{minHeight: "59px"}}>
                              <td className="col-1 px-3">
                                {/* <div className="d-flex justify-content-center">
                                  <input
                                    className="amountEditor"
                                    type="button"
                                    value={"-"}
                                    hidden={
                                      order
                                        ? order.order_status == 1
                                          ? false
                                          : true
                                        : false
                                    }
                                    onClick={(e) => {
                                      const newArr = orderConcept.map(
                                        (data) => {
                                          if (
                                            data.product_id ==
                                            product.product_id
                                          ) {
                                            return {
                                              ...data,
                                              amount: data.amount - 1,
                                            };
                                          } else {
                                            return data;
                                          }
                                        }
                                      );
                                      product.amount >= 1
                                        ? setOrderConcept(newArr)
                                        : setOrderConcept(orderConcept);
                                    }}
                                  ></input>
                                  {order
                                    ? order.order_status !== 0
                                      ? product.amount
                                      : ""
                                    : ""}
                                  <input
                                    hidden={
                                      order
                                        ? order.order_status == 1
                                          ? false
                                          : true
                                        : false
                                    }
                                    type="number"
                                    min={0}
                                    className="saleInputStyle"
                                    value={product.amount}
                                    onBlur={(e) => {
                                      const newArr = orderConcept.map(
                                        (data) => {
                                          if (
                                            data.product_id ==
                                            product.product_id
                                          ) {
                                            return {
                                              ...data,
                                              amount:
                                                product.amount > 0
                                                  ? e.target.value
                                                  : 0,
                                            };
                                          } else {
                                            return data;
                                          }
                                        }
                                      );
                                      setOrderConcept(newArr);
                                    }}
                                    onChange={(e) => {
                                      const newArr = orderConcept.map(
                                        (data) => {
                                          if (
                                            data.product_id ==
                                            product.product_id
                                          ) {
                                            return {
                                              ...data,
                                              amount: e.target.value,
                                            };
                                          } else {
                                            return data;
                                          }
                                        }
                                      );
                                      setOrderConcept(newArr);
                                    }}
                                  ></input>
                                  <input
                                    className="amountEditor"
                                    type="button"
                                    value={"+"}
                                    hidden={
                                      order
                                        ? order.order_status == 1
                                          ? false
                                          : true
                                        : false
                                    }
                                    onClick={(e) => {
                                      const newArr = orderConcept.map(
                                        (data) => {
                                          if (
                                            data.product_id ==
                                            product.product_id
                                          ) {
                                            return {
                                              ...data,
                                              amount: Number(data.amount) + 1,
                                            };
                                          } else {
                                            return data;
                                          }
                                        }
                                      );
                                      setOrderConcept(newArr);
                                    }}
                                  ></input>
                                </div> */}
                                <div className="amountParent">
                                  <button className="amountCountButtons fw-semibold fs-5">-</button>
                                  <label className="p-2 amountCount px-3">1</label>
                                  <button className="amountCountButtons  fw-semibold fs-5">+</button>
                                </div>
                              </td>
                              <td
                                className="text-start text-info px-3 d-flex align-items-center"
                                style={{ maxWidth: "30vw" }}
                              >
                                <div style={{minHeight: "43px"}} className=" fitcontentHeight d-flex justify-content-center ">
                                  <div className="d-flex align-self-center mb-0 cursorPointer" onClick={() => setShowOrderRow(true)} >{product.product_name}</div> 
                                </div>
                              </td>
                              <td style={{width: "11%"}}>
                                <div style={{minHeight: "43px"}} className=" fitcontentHeight d-flex justify-content-center ">
                                  <div className="d-flex align-self-center mb-0">€69,00</div> 
                                </div>
                                {/* {btwState == "incl"
                                  ? Number(product.price_incl_tax)
                                      .toFixed(2)
                                      .toString()
                                      .replace(".", ",")
                                  : Number(product.price_excl_tax)
                                      .toFixed(2)
                                      .toString()
                                      .replace(".", ",")} */}
                              </td>
                              <td style={{width: "11%"}}>
                                <div style={{minHeight: "43px"}} className=" fitcontentHeight d-flex justify-content-center ">
                                  <div className="d-flex align-self-center mb-0">
                                    <input className="p-2 inputSaleOrder text-center fw-semibold me-2" placeholder="10"/>
                                    <div className="d-flex flex-row">
                                      <button className={`euroSaleButton p-2 fw-semibold ${activeSaleTypeProduct == 1 ? "activeSaleType" : ""}`} onClick={() => setActiveSaleTypeProduct(1)}>€</button>
                                      <button className={`percentageSaleButton p-2 fw-semibold ${activeSaleTypeProduct == 2 ? "activeSaleType" : ""}`} onClick={() => setActiveSaleTypeProduct(2)}>%</button>
                                    </div>
                                  </div> 
                                </div>
                                {/* <div className="d-flex">
                                  {order ? (
                                    order.order_status !== 1 ? (
                                      product.sale_unit == "€" ? (
                                        "€" + product.sale_amount
                                      ) : (
                                        product.sale_amount + "%"
                                      )
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    <>
                                      <input
                                        min={0}
                                        type="number"
                                        className="saleInputStyle"
                                        value={product.sale_amount ?? ""}
                                        disabled={
                                          order
                                            ? order.order_status === 0
                                              ? true
                                              : false
                                            : false
                                        }
                                        onChange={(e) => {
                                          const newArr = orderConcept.map(
                                            (data) => {
                                              if (
                                                data.product_id ==
                                                product.product_id
                                              ) {
                                                return {
                                                  ...data,
                                                  sale_amount: e.target.value,
                                                };
                                              } else {
                                                return data;
                                              }
                                            }
                                          );
                                          setOrderConcept(newArr);
                                        }}
                                        onBlur={(e) => {
                                          const newArr = orderConcept.map(
                                            (data) => {
                                              if (
                                                data.product_id ==
                                                product.product_id
                                              ) {
                                                return {
                                                  ...data,
                                                  sale_amount:
                                                    e.target.value > 0
                                                      ? e.target.value >
                                                          100 &&
                                                        data.sale_unit == "%"
                                                        ? 100
                                                        : data.sale_unit ==
                                                            "€" &&
                                                          data.price_incl_tax <
                                                            e.target.value
                                                        ? data.price_incl_tax.toFixed(
                                                            2
                                                          )
                                                        : e.target.value
                                                      : 0,
                                                };
                                              } else {
                                                return data;
                                              }
                                            }
                                          );
                                          setOrderConcept(newArr);
                                        }}
                                      ></input>
                                      <input
                                        type="button"
                                        value="€"
                                        hidden={
                                          order
                                            ? order.order_status === 1 &&
                                              order.sales_unit === "€"
                                              ? true
                                              : false
                                            : false
                                        }
                                        onClick={(e) => {
                                          const newArr = orderConcept.map(
                                            (data) => {
                                              if (
                                                data.product_id ==
                                                product.product_id
                                              ) {
                                                return {
                                                  ...data,
                                                  sale_unit: e.target.value,
                                                };
                                              } else {
                                                return data;
                                              }
                                            }
                                          );
                                          setOrderConcept(newArr);
                                        }}
                                        className={
                                          product.sale_unit == "€"
                                            ? "saleTypeStyle activeSaleTypeStyle"
                                            : "saleTypeStyle"
                                        }
                                        name="type"
                                      ></input>
                                      <input
                                        type="button"
                                        value="%"
                                        hidden={
                                          order
                                            ? order.order_status === 1 &&
                                              order.sales_unit === "%"
                                              ? true
                                              : false
                                            : false
                                        }
                                        onClick={(e) => {
                                          const newArr = orderConcept.map(
                                            (data) => {
                                              if (
                                                data.product_id ==
                                                product.product_id
                                              ) {
                                                return {
                                                  ...data,
                                                  sale_unit: e.target.value,
                                                };
                                              } else {
                                                return data;
                                              }
                                            }
                                          );
                                          setOrderConcept(newArr);
                                        }}
                                        className={
                                          product.sale_unit == "%"
                                            ? "saleTypeStyle activeSaleTypeStyle"
                                            : "saleTypeStyle"
                                        }
                                        name="type"
                                      ></input>
                                    </>
                                  )}
                                </div> */}
                              </td>
                              <td style={{width: "11%"}}>
                                <div style={{minHeight: "43px"}} className=" fitcontentHeight d-flex justify-content-center ">
                                  <div className="d-flex align-self-center mb-0">€69,00</div> 
                                </div>
                                {/* {(
                                  ((btwState == "incl"
                                    ? product.price_incl_tax
                                    : product.price_excl_tax) -
                                    (product.sale_unit == "€"
                                      ? product.sale_amount
                                      : ((btwState == "incl"
                                          ? product.price_incl_tax
                                          : product.price_excl_tax) /
                                          100) *
                                        product.sale_amount)) *
                                  product.amount
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace(".", ",")} */}
                              </td>
                              <td style={{width: "8%"}}>
                                <div style={{minHeight: "43px"}} className=" fitcontentHeight d-flex justify-content-center ">
                                  <div className="d-flex align-self-center mb-0">€69,00</div> 
                                </div>
                                {/* {(
                                  ((btwState == "incl"
                                    ? product.price_incl_tax
                                    : product.price_excl_tax) -
                                    (product.sale_unit == "€"
                                      ? product.sale_amount
                                      : ((btwState == "incl"
                                          ? product.price_incl_tax
                                          : product.price_excl_tax) /
                                          100) *
                                        product.sale_amount)) *
                                  product.amount
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace(".", ",")} */}
                              </td>
                              <td
                                style={{width: "20px"}}
                                className="px-3"
                                onClick={(e) => {
                                  setOrderConcept([
                                    ...orderConcept.filter(
                                      (filter) =>
                                        filter.product_id !==
                                        product.product_id
                                    ),
                                  ]);
                                }}
                              >
                                <div style={{minHeight: "43px"}} className=" cursorPointer fitContent fitcontentHeight d-flex justify-content-center ">
                                  <div className="d-flex align-self-center mb-0"><FontAwesomeIcon icon={faX} /></div> 
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-end">
                        <div className="d-flex flex-row justify-content-around" style={{width: "31%"}}>
                          <h5 className="fw-semibold">Subtotaal</h5>
                          <div className="fw-semibold">€621,00</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-between noteSubtotal px-3 mb-5">
                  <div className="col-6 pe-4 d-flex flex-column parentNote">
                    <div className="fs-5 mb-3">Opmerking op factuur</div>
                    <textarea
                      placeholder="Opmerking op factuur"
                      name="note"
                      className="textareaStyleOrders p-3"
                      value={order ? order.note : orderInfo.note}
                      onChange={(e) =>
                        order
                          ? setOrder({ ...order, note: e.target.value })
                          : setOrderInfo({
                              ...orderInfo,
                              note: e.target.info,
                            })
                      }
                    ></textarea>
                  </div>
                  <div className="col-6 d-flex flex-column">
                    <div className="fs-5 mb-3">Bestelling overzicht</div>
                    <div
                      className="h-100 p-3 pt-2 rounded-4 parentSubTotaal"
                      style={{ border: "2px solid #f3f3f3" }}
                    >
                      <div>
                        <div className="d-flex justify-content-between discountOrder mb-3">
                          <div className=" fw-semibold d-flex align-items-center">Korting over bestelling</div>
                          <div className="d-flex buttonswitch">
                            <div className="d-flex align-content-center mb-0">
                              <input className="p-2 inputSaleOrder text-center fw-semibold me-2" placeholder="10"/>
                              <div className="d-flex flex-row">
                                <button className={`euroSaleButton p-2 fw-semibold ${activeSaleTypeOrder == 1 ? "activeSaleType" : ""}`} onClick={() => setActiveSaleTypeOrder(1)}>€</button>
                                <button className={`percentageSaleButton p-2 fw-semibold ${activeSaleTypeOrder == 2 ? "activeSaleType" : ""}`} onClick={() => setActiveSaleTypeOrder(2)}>%</button>
                              </div>
                            </div> 
                          </div>
                        </div>
                        <div className="d-flex justify-content-between subTotal mb-4">
                          <select className="standardDropdownArrow standardInput px-3 pe-4 w-50">
                            <option hidden>Verzendmethode</option>
                            <option>Ophalen</option>
                            {shippingMethods.map((map) => (
                              <option
                                key={map.shipping_id}
                                value={map.shipping_id}
                              >
                                {map.title}
                              </option>
                            ))}
                          </select>
                          <div className="d-flex justify-content-end shippingCosts">
                            <div className="d-flex align-content-center mb-0">
                              <div className="me-3 fwPerfect d-flex align-items-center">€6,95</div>
                              <input className="p-2 inputSaleOrder text-center fw-semibold me-2" placeholder="0"/>
                              <div className="d-flex flex-row">
                                <button className={`euroSaleButton p-2 fw-semibold ${activeSaleType == 1 ? "activeSaleType" : ""}`} onClick={() => setActiveSaleType(1)}>€</button>
                                <button className={`percentageSaleButton p-2 fw-semibold ${activeSaleType == 2 ? "activeSaleType" : ""}`} onClick={() => setActiveSaleType(2)}>%</button>
                              </div>
                            </div> 
                          </div>
                        </div>
                        <div>
                          <div className="d-flex flex-row justify-content-between mb-2">
                            <div className="fw-semibold">Totale korting</div>
                            <div className="fw-semibold">€69,00</div>
                          </div>
                          <div className="d-flex flex-row justify-content-between mb-2">
                            <div className="fw-semibold">Totale excl. BTW</div>
                            <div className="fw-semibold">€532,64</div>
                          </div>
                          <div className="d-flex flex-row justify-content-between mb-2">
                            <div className="fw-semibold">Totale incl. BTW</div>
                            <div className="fw-semibold">€644,50</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex flex-column">
                  <div className="fs-5 mt-3 mb-2">Details</div>
                  <div className="d-flex dropdownDetails">
                    <div className="col-3 me-3 ordersProcessing">
                      <div className="text-nowrap">
                        Bestelling verwerken
                      </div>
                      <select
                        defaultValue={0}
                        className="borderedSearchStyle selectDetails"
                        name="order_processing"
                      >
                        <option value={0}>Bestelverzoek</option>
                      </select>
                    </div>
                    <div className="col-3 ms-3 sellLocation">
                      <div>Verkooplocatie</div>
                      <select
                        defaultValue={0}
                        className="borderedSearchStyle selectDetails"
                        name="sales_channel_id"
                      >
                        {allSaleschannels.map((map) => (
                          <option
                            key={map.sales_channel_id}
                            value={map.sales_channel_id}
                          >
                            {map.sales_channel_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-3">
                                            <div>Status</div>
                                            <select defaultValue={0} className="borderedSearchStyle selectDetails" name="status">
                                                <option value={0}>Nieuw</option>
                                            </select>
                                        </div>
                  </div>
                </div> */}
              </div>
              <div className="col-12 d-flex flex-row justify-content-between">
                <div className="bg-white rounded-4 p-4" style={{width: "49%"}}>
                  <h5 className="fw-semibold mb-3">Details</h5>
                  <div className="mb-3">
                    <div className="fw-semibold mb-2 d-flex flex-row">Bestelling verwerken <img src={info} className="d-flex align-self-center ms-2" style={{height: "20px", width: "20px"}}/></div>
                    <select className="standardInput standardDropdownArrow px-3 w-100">
                      <option>Bestelling verwerken</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <div className="fw-semibold mb-2 d-flex flex-row">Verkooplocatie <img src={info} className="d-flex align-self-center ms-2" style={{height: "20px", width: "20px"}}/></div>
                    <select className="standardInput standardDropdownArrow px-3 w-100">
                      {allSaleschannels.map((map) => (
                        <option
                          key={map.sales_channel_id}
                          value={map.sales_channel_id}
                        >
                          {map.sales_channel_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <div className="fw-semibold mb-2 d-flex flex-row">Status <img src={info} className="d-flex align-self-center ms-2" style={{height: "20px", width: "20px"}}/></div>
                    <select className="standardInput standardDropdownArrow px-3 w-100">
                      <option>Niet verzonden</option>
                    </select>
                  </div>
                </div>
                <div className="bg-white rounded-4 p-4" style={{width: "49%"}}>
                  <h5 className="fw-semibold">Extra informatie</h5>
                  <div style={{height: "250px"}}>
                    <textarea
                      placeholder="Referentie op factuur"
                      name="note"
                      className="textareaStyleOrders w-100 p-3"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </PageTemplate>
  );
};

export default OrdersNew;