import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router";
import InputComponent from '../../AddCustomers/InputComponent';
import Plus from "../../AddCustomers/Icons/Add.svg";
import Minus from "../../AddCustomers/Icons/minus.svg"; // You need to add this icon to your project
import middleware from "../../../Api/Middleware";
import ToastError from "../../../Toasts/ToastError";
import LoadingScreen from "../../../LoadingScreen/LoadingScreen";
import ToastSuccess from "../../../Toasts/ToastSuccess";

const GeneralInfoTab = ({ typeCustomer, setDataUpdated }) => {
  const { internal_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [consumerData, setConsumerData] = useState({});
  const [businessInfo, setBusinessInfo] = useState({});
  const [emailSettings, setEmailSettings] = useState({});
  const [emails, setEmails] = useState([]);
  const [adress, setAdress] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [removedEmails, setRemovedEmails] = useState([]); // State for removed emails
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [removedPhoneNumbers, setRemovedPhoneNumbers] = useState([]); // State for removed phone numbers
  const [initialData, setInitialData] = useState({});
  const [formData, setFormData] = useState({});
  const formRef = useRef(null); // Step 1: Create a ref for the form
  const vatPatterns = {
    AT: /^ATU\d{8}$/,                        // Austria
    BE: /^BE0\d{9}$/,                        // Belgium
    BG: /^BG\d{9,10}$/,                      // Bulgaria
    CY: /^CY\d{8}[A-Z]$/,                    // Cyprus
    CZ: /^CZ\d{8,10}$/,                      // Czech Republic
    DE: /^DE\d{9}$/,                         // Germany
    DK: /^DK\d{8}$/,                         // Denmark
    EE: /^EE\d{9}$/,                         // Estonia
    EL: /^EL\d{9}$/,                         // Greece
    ES: /^ES[A-Z0-9]\d{7}[A-Z0-9]$/,         // Spain
    FI: /^FI\d{8}$/,                         // Finland
    FR: /^FR[A-Z0-9]{2}\d{9}$/,              // France
    GB: /^GB(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/, // United Kingdom
    HR: /^HR\d{11}$/,                        // Croatia
    HU: /^HU\d{8}$/,                         // Hungary
    IE: /^IE\d[A-Z0-9+*]\d{5}[A-Z]$/,        // Ireland
    IT: /^IT\d{11}$/,                        // Italy
    LT: /^LT(\d{9}|\d{12})$/,                // Lithuania
    LU: /^LU\d{8}$/,                         // Luxembourg
    LV: /^LV\d{11}$/,                        // Latvia
    MT: /^MT\d{8}$/,                         // Malta
    NL: /^NL\d{9}B\d{2}$/,                   // Netherlands
    PL: /^PL\d{10}$/,                        // Poland
    PT: /^PT\d{9}$/,                         // Portugal
    RO: /^RO\d{2,10}$/,                      // Romania
    SE: /^SE\d{12}$/,                        // Sweden
    SI: /^SI\d{8}$/,                         // Slovenia
    SK: /^SK\d{10}$/                         // Slovakia
    // Add more countries as needed
};

  const fetchCorrectConsumer = async (internalId) => {
    if (internalId) {
      setLoading(true);
      try {
        let initialData = {};
        if (internalId.startsWith('B')) {
          const [businessResponse, businessContactResponse, emailRes, phoneNumberRes, emailSettingsRes] = await Promise.all([
            middleware.get(`businesses?internal_id=${internalId}`),
            middleware.get(`businessescontacts?internal_id=${internalId}`),
            middleware.get(`emails?internal_id=${internalId}`),
            middleware.get(`phonenumbers?internal_id=${internalId}`),
            middleware.get(`emailsettings?internal_id=${internalId}`)
          ]);

          setBusinessInfo(businessResponse.data[0]);
          setConsumerData(businessContactResponse.data[0]);
          setEmails(emailRes.data);
          setPhoneNumbers(phoneNumberRes.data);
          setEmailSettings(emailSettingsRes.data[0]);

          initialData = {
            business: businessResponse.data[0],
            business_contact: businessContactResponse.data[0],
            emails: emailRes.data,
            phone_numbers: phoneNumberRes.data,
            email_settings: emailSettingsRes.data[0],
          };
        } else if (internalId.startsWith('C')) {
          const [consumerResponse, emailRes, phoneNumberRes, emailSettingsRes] = await Promise.all([
            middleware.get(`consumers?internal_id=${internalId}`),
            middleware.get(`emails?internal_id=${internalId}`),
            middleware.get(`phonenumbers?internal_id=${internalId}`),
            middleware.get(`emailsettings?internal_id=${internalId}`)
          ]);

          setConsumerData(consumerResponse.data[0]);
          setEmails(emailRes.data);
          setPhoneNumbers(phoneNumberRes.data);
          setEmailSettings(emailSettingsRes.data[0]);

          initialData = {
            consumer: consumerResponse.data[0],
            emails: emailRes.data,
            phone_numbers: phoneNumberRes.data,
            email_settings: emailSettingsRes.data[0],
          };
        }
        const [adressesResponse, countrycodesResponses] = await Promise.all([
          middleware.get(`addresses?internal_id=${internalId}`),
          middleware.get(`countryCodes`),
        ]);

        countrycodesResponses.data.forEach(countrycode => {
          if (countrycode.country_code_id == adressesResponse.data[0].country_code_id) {
            setCountryCodes(countrycode)
          }
        });
        setInitialData(initialData);
        setFormData(structuredClone(initialData)); // Initialize formData with a deep copy of initial data
        setDataUpdated(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchCorrectConsumer(internal_id);
  }, [internal_id]);

  const updateNestedFormData = useCallback((key, value, fieldType) => {
    setFormData(prev => ({
      ...prev,
      [fieldType]: {
        ...structuredClone(prev[fieldType]), // Clone the nested object to avoid mutating the original
        [key]: value,
      }
    }));
  }, []);

  const getValueInput = useCallback((value, label) => {
    // if (label === 'vatnumber') {
    //   if (!validateVATNumber(countryCodes.country_code_2, value)) {
    //     ToastError("BTW-nummer is niet geldig voor het geselecteerde land.");
    //     return; // Stop further processing if VAT number is invalid
    //   }
    // }
    
    if (label in businessInfo) {
      updateNestedFormData(label, value, 'business');
    } else if (label in consumerData) {
      updateNestedFormData(label, value, 'business_contact');
    } else if (label in emailSettings) {
      updateNestedFormData(label, value, 'email_settings');
    }
  }, [businessInfo, consumerData, emailSettings, countryCodes]);
  

  const handleBlurInput = useCallback((value, label) => {
    // if (label === 'vatnumber') {
    //   if (!validateVATNumber(countryCodes.country_code_2, value)) {
    //     ToastError("BTW-nummer is niet geldig voor het geselecteerde land.");
    //     return; // Stop further processing if VAT number is invalid
    //   }
    // }
  
    if (label in businessInfo) {
      updateNestedFormData(label, value, 'business');
    } else if (label in consumerData) {
      updateNestedFormData(label, value, 'business_contact');
    } else if (label in emailSettings) {
      updateNestedFormData(label, value, 'email_settings');
    }
  }, [businessInfo, consumerData, emailSettings, countryCodes]);
  

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
  
    const changedData = {};
    const newEmails = formData.emails.filter(email => !email.email_id); // Detect new emails without ID
    const newPhoneNumbers = formData.phone_numbers.filter(phone => !phone.phone_number_id); // Detect new phone numbers without ID
  
    // Handle changes in existing data
    if (typeCustomer === "B") {
      if (JSON.stringify(initialData.business) !== JSON.stringify(formData.business)) {
        changedData.business = formData.business;
      }
      if (JSON.stringify(initialData.business_contact) !== JSON.stringify(formData.business_contact)) {
        changedData.business_contact = formData.business_contact;
      }
    } else if (typeCustomer === "C") {
      if (JSON.stringify(initialData.consumer) !== JSON.stringify(formData.consumer)) {
        changedData.consumer = formData.consumer;
      }
    }
  
    if (JSON.stringify(initialData.emails) !== JSON.stringify(formData.emails)) {
      changedData.emails = formData.emails.filter(email => email.email_id); // Only include existing emails
    }
  
    if (JSON.stringify(initialData.phone_numbers) !== JSON.stringify(formData.phone_numbers)) {
      changedData.phone_numbers = formData.phone_numbers.filter(phone => phone.phone_number_id); // Only include existing phone numbers
    }
  
    let hasError = false; // Track if any error occurs
  
    try {
      // Send POST requests for new emails and phone numbers
      if (newEmails.length > 0) {
        try {
          await Promise.all(newEmails.map(email =>
            middleware.post(`emails`, { internal_id, email: email.email })
          ));
        } catch (error) {
          hasError = true;
          ToastError("Email format is incorrect. voorbeeld: JohnDoe@gmail.com");
        }
      }
      
      if (newPhoneNumbers.length > 0) {
        try {
          await Promise.all(newPhoneNumbers.map(phone =>
            middleware.post(`phonenumbers`, { internal_id, phone_number: phone.phone_number })
          ));
        } catch (error) {
          hasError = true;
          ToastError("Telefoonummer format is incorrect.");
          console.log(error);
        }
      }
  
      // Handle PUT requests for existing data
      if (Object.keys(changedData).length > 0) {
        if (changedData.business) {
          await middleware.put(`businesses?internal_id=${internal_id}`, changedData.business);
        }
        if (changedData.business_contact) {
          await middleware.put(`businessescontacts?internal_id=${internal_id}`, changedData.business_contact);
        }
        if (changedData.consumer) {
          await middleware.put(`consumers?internal_id=${internal_id}`, changedData.consumer);
        }
        if (changedData.emails) {
          await Promise.all(changedData.emails.map(email =>
            middleware.put(`emails?internal_id=${internal_id}`, { 
              email_id: email.email_id,
              email: email.email,
            }).catch(error => {
              hasError = true;
              ToastError("Email format is incorrect. voorbeeld: JohnDoe@gmail.com");
            })
          ));
        }
        if (changedData.phone_numbers) {
          await Promise.all(changedData.phone_numbers.map(phone_number => 
            middleware.put(`phonenumbers?internal_id=${internal_id}`, { 
              phone_number_id: phone_number.phone_number_id,
              phone_number: phone_number.phone_number,
            }).catch(error => {
              hasError = true;
              ToastError(`Telefoonnummer kan niet langer zijn dan 15 characters`);
            })
          ));
        }
      }
  
      // Check for errors before showing success message
      if (hasError) {
        
      } else if (Object.keys(changedData).length > 0 || newEmails.length > 0 || newPhoneNumbers.length > 0) {
        ToastSuccess("All changes saved successfully!");
        // Update initialData to reflect the new state after save
        setInitialData(structuredClone(formData));
      } else {
        ToastError("No changes detected.");
      }
  
      // Refetch the data to get the updated data including newly created IDs
      fetchCorrectConsumer(internal_id);
  
    } catch (error) {
      console.error('Error submitting data:', error);
      ToastError("Error submitting data.");
    }
  }, [formData, initialData, typeCustomer, internal_id]);
  
  

  const addEmailField = () => {
    if (emails.length < 3) {
      setEmails([...emails, '']);
    } else {
      ToastError("Je kunt maximaal 3 e-mailadressen toevoegen");
    }
  };

  const handleEmailChange = (index, value) => {
    const newEmails = structuredClone(emails);
    if (typeof newEmails[index] === 'object') {
      newEmails[index].email = value;
    } else {
      newEmails[index] = { email: value };
    }
    setEmails(newEmails);
    setFormData(prev => ({
      ...prev,
      emails: newEmails
    }));
  };

  const handleEmailBlur = (index, value) => {
    const newEmails = structuredClone(emails);
    if (typeof newEmails[index] === 'object') {
      newEmails[index].email = value;
    } else {
      newEmails[index] = { email: value };
    }
    setEmails(newEmails);
    setFormData(prev => ({
      ...prev,
      emails: newEmails
    }));
  };

  const removeEmailField = async (index) => {
    const confirmDelete = window.confirm("Weet je zeker dat je deze e-mail wilt verwijderen?");
    if (confirmDelete) {
      const emailToRemove = emails[index];
  
      if (emailToRemove.email_id) { // Only delete if the email has an ID
        console.log(emailToRemove.email_id);
        try {
          await middleware.delete(`/emails?email_id=${emailToRemove.email_id}`);
          ToastSuccess("E-mail succesvol verwijderd!");
        } catch (error) {
          console.error('Error deleting email:', error);
          ToastError("Er is een fout opgetreden bij het verwijderen van de e-mail.");
          return;
        }
      }
  
      // Update state after successful deletion
      const newEmails = emails.filter((_, i) => i !== index);
      setEmails(newEmails);
      setFormData(prev => ({
        ...prev,
        emails: newEmails
      }));
    }
  };

  const validateVATNumber = (countryCode, vatNumber) => {
    const pattern = vatPatterns[countryCode];
    if (!pattern) {
      ToastError("Country code is invalid or not supported.");
      return false;
    }
    return pattern.test(vatNumber);
  };
  
  

  const addPhoneNumberField = () => {
    if (phoneNumbers.length < 3) {
      setPhoneNumbers([...phoneNumbers, '']);
    } else {
      ToastError("Je kunt maximaal 3 telefoonnummers toevoegen");
    }
  };

  const handlePhoneNumberChange = (index, value) => {
    const newPhoneNumbers = structuredClone(phoneNumbers); // Create a deep copy to avoid mutating initialData
    if (typeof newPhoneNumbers[index] === 'object') {
      newPhoneNumbers[index].phone_number = value;
    } else {
      newPhoneNumbers[index] = { phone_number: value };
    }
    setPhoneNumbers(newPhoneNumbers);
    setFormData(prev => ({
      ...prev,
      phone_numbers: newPhoneNumbers
    }));
  };
  
  const handlePhoneNumberBlur = (index, value) => {
    const newPhoneNumbers = structuredClone(phoneNumbers); // Create a deep copy to avoid mutating initialData
    if (typeof newPhoneNumbers[index] === 'object') {
      newPhoneNumbers[index].phone_number = value;
    } else {
      newPhoneNumbers[index] = { phone_number: value };
    }
    setPhoneNumbers(newPhoneNumbers);
    setFormData(prev => ({
      ...prev,
      phone_numbers: newPhoneNumbers
    }));
  };
  
  const removePhoneNumberField = async (index) => {
    const confirmDelete = window.confirm("Weet je zeker dat je dit telefoonnummer wilt verwijderen?");
    if (confirmDelete) {
      const phoneNumberToRemove = phoneNumbers[index];
  
      if (phoneNumberToRemove.phone_number_id) { // Only delete if the phone number has an ID
        try {
          await middleware.delete(`phonenumbers/${phoneNumberToRemove.phone_number_id}`);
          ToastSuccess("Telefoonnummer succesvol verwijderd!");
        } catch (error) {
          console.error('Error deleting phone number:', error);
          ToastError("Er is een fout opgetreden bij het verwijderen van het telefoonnummer.");
          return;
        }
      }
  
      // Update state after successful deletion
      const newPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
      setPhoneNumbers(newPhoneNumbers);
      setFormData(prev => ({
        ...prev,
        phone_numbers: newPhoneNumbers
      }));
    }
  };  

  const handleEmailSettingsChange = async (settingKey, value) => {
    const updatedSettings = { ...emailSettings, [settingKey]: value ? 1 : 0 };
    setEmailSettings(updatedSettings);
  
    try {
      await middleware.put(`emailsettings?internal_id=${internal_id}`, updatedSettings);
      ToastSuccess("Voorkeur successvol veranderd!");
    } catch (error) {
      console.error('Er ging iets fout met het veranderen van de voorkeur:', error);
      ToastError("Er ging iets fout met het veranderen van de voorkeur");
    }
  };
  

  if (loading) {
    return <h2 className="m-4 loader"></h2>
  }

  return (
    <div className="px-4 py-4">
      <form className="col-12" ref={formRef} onBlur={handleSubmit}>
        {typeCustomer === "B" &&
          <div>
            <label className='fw-bold mb-2 secondaryTitles'>Bedrijfsgegevens</label>
            <div className='col-12 d-flex flex-row align-content-end mb-3'>
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                onBlur={handleBlurInput}
                alignmentInputs={"justify-content-start"}
                parentCol={12}
                defaultValue={businessInfo.company_name || ""}
                labelName={"Bedrijfsnaam"}
                labelValue={"company_name"}
                classnamesInput={"col-12 SearchIcon px-3"}
              />
            </div>
            <div className="col-12 d-flex flex-row align-content-end mb-4">
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                onBlur={handleBlurInput}
                alignmentInputs={"justify-content-start"}
                parentCol={6}
                defaultValue={businessInfo.cocnumber || ""}
                labelValue={"cocnumber"}
                labelName={"KVK nummer"}
                classnamesInput={"col-12 widthInputComponents SearchIcon px-3"}
              />
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                onBlur={handleBlurInput}
                alignmentInputs={"justify-content-end"}
                parentCol={6}
                defaultValue={businessInfo.vatnumber || ""}
                labelValue={"vatnumber"}
                labelName={"BTW nummer"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
            </div>
          </div>
        }
        <div>
          <label className={`fw-bold mb-2 secondaryTitles ${typeCustomer === "B" ? "" : "d-none"}`}>Contactpersoon</label>
          <div className="col-12 d-flex flex-row align-content-end mb-3">
            <InputComponent
              type={"text"}
              onValueChange={getValueInput}
              onBlur={handleBlurInput}
              alignmentInputs={"justify-content-start"}
              parentCol={6}
              defaultValue={consumerData?.first_name || ""}
              labelValue={"first_name"}
              labelName={"Voornaam"}
              classnamesInput={"col-12 widthInputComponents px-3"}
            />
            <InputComponent
              type={"text"}
              onValueChange={getValueInput}
              onBlur={handleBlurInput}
              alignmentInputs={"justify-content-end"}
              parentCol={6}
              defaultValue={consumerData?.last_name || ""}
              labelValue={"last_name"}
              labelName={"Achternaam"}
              classnamesInput={"col-12 widthInputComponents px-3"}
            />
          </div>
          <div className="col-12 d-flex flex-row align-content-end my-3">
            <div className="d-flex flex-column col-6 pe-3">
              <div className="d-flex justify-content-between">
                <label className='fw-semibold mb-2'>Emails</label>
                <img
                  className='pe-3 addIconSizeModals'
                  src={Plus}
                  onClick={addEmailField}
                  alt="Add"
                  style={{ cursor: 'pointer' }}
                />
              </div>
              {emails.map((email, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <input
                    className={`inputFieldAddCustomer px-3`}
                    type="email"
                    required={true}
                    style={{width: "97%"}}
                    value={email.email || ""}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    onBlur={(e) => handleEmailBlur(index, e.target.value)}
                  />
                  <img
                    className='ms-2 removeIconSizeModals'
                    src={Minus}
                    onClick={() => removeEmailField(index)}
                    alt="Remove"
                    style={{ cursor: 'pointer', width:"3%" }}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex flex-column col-6 ps-3">
              <div className="d-flex justify-content-between">
                <label className='fw-semibold mb-2'>Telefoonnummers</label>
                <img
                  className='pe-3 addIconSizeModals'
                  src={Plus}
                  onClick={addPhoneNumberField}
                  alt="Add"
                  style={{ cursor: 'pointer' }}
                />
              </div>
              {phoneNumbers.map((phoneNumber, index) => (
                <div key={index} className="d-flex align-items-center mb-2 w-100">
                  <input
                    className={`inputFieldAddCustomer px-3`}
                    type="tel"
                    style={{width: "97%"}}
                    required={true}
                    value={phoneNumber.phone_number || ""}
                    onChange={(e) => handlePhoneNumberChange(index, e.target.value)}
                    onBlur={(e) => handlePhoneNumberBlur(index, e.target.value)}
                  />
                  <img
                    className='ms-2 removeIconSizeModals'
                    src={Minus}
                    onClick={() => removePhoneNumberField(index)}
                    alt="Remove"
                    style={{ cursor: 'pointer', width:"3%" }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 d-flex flex-row align-content-end my-3">
            <InputComponent
              type={"date"}
              onValueChange={getValueInput}
              onBlur={handleBlurInput}
              parentCol={6}
              defaultValue={consumerData?.birthday || ""}
              labelValue={"birthday"}
              labelName={"Geboortedatum"}
              classnamesInput={"col-12 widthInputComponents diffDateIcon px-3"}
            />
            <div className="col-6 d-flex justify-content-start">
              <div className="col-4 d-flex flex-column">
                <label className="mb-2 ms-5 fw-medium fw-semibold">Nieuwsbrief</label>
                <label className="switchNewCustomer ms-4">
                  <input
                    type="checkbox"
                    checked={emailSettings?.newsletter === 1}
                    onChange={(e) => handleEmailSettingsChange('newsletter', e.target.checked)}
                  />
                  <span className="sliderNewCustomer round"></span>
                </label>
              </div>
              <div className=" col-4  d-flex flex-column">
                <label className="mb-2 ms-5 fw-semibold">Verjaardagactie</label>
                <label className="switchNewCustomer ms-4">
                  <input
                    type="checkbox"
                    checked={emailSettings?.birthday_promotion === 1}
                    onChange={(e) => handleEmailSettingsChange('birthday_promotion', e.target.checked)}
                  />
                  <span className="sliderNewCustomer round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default GeneralInfoTab;
