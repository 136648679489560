import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import middleware from '../../Api/Middleware';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Customer.css"
import InputComponent from './InputComponent';
import ToastSuccess from '../../Toasts/ToastSuccess';
import ToastError from '../../Toasts/ToastError';
import Plus from "./Icons/Add.svg";

const AddCompanyModal = ({show, close}) => {
  const [showCompany, setShowCompany] = useState(false);
  const [countryInfo, setCountryInfo] = useState({});
  const [countryIdInfo, setCountryIdInfo] = useState([]);
  const [emails, setEmails] = useState(['']);
  const [phoneNumbers, setPhoneNumbers] = useState(['']);
  const [countryId, setCountryId] = useState();
  const [formData, setFormData] = useState({
    newsPaper: false,
    birthday: false,
  });
  const vatRegexPatterns = {
    AT: /^ATU\d{8}$/,                        // Austria
    BE: /^BE0\d{9}$/,                        // Belgium
    BG: /^BG\d{9,10}$/,                      // Bulgaria
    CY: /^CY\d{8}[A-Z]$/,                    // Cyprus
    CZ: /^CZ\d{8,10}$/,                      // Czech Republic
    DE: /^DE\d{9}$/,                         // Germany
    DK: /^DK\d{8}$/,                         // Denmark
    EE: /^EE\d{9}$/,                         // Estonia
    EL: /^EL\d{9}$/,                         // Greece
    ES: /^ES[A-Z0-9]\d{7}[A-Z0-9]$/,         // Spain
    FI: /^FI\d{8}$/,                         // Finland
    FR: /^FR[A-Z0-9]{2}\d{9}$/,              // France
    GB: /^GB(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/, // United Kingdom
    HR: /^HR\d{11}$/,                        // Croatia
    HU: /^HU\d{8}$/,                         // Hungary
    IE: /^IE\d[A-Z0-9+*]\d{5}[A-Z]$/,        // Ireland
    IT: /^IT\d{11}$/,                        // Italy
    LT: /^LT(\d{9}|\d{12})$/,                // Lithuania
    LU: /^LU\d{8}$/,                         // Luxembourg
    LV: /^LV\d{11}$/,                        // Latvia
    MT: /^MT\d{8}$/,                         // Malta
    NL: /^NL\d{9}B\d{2}$/,                   // Netherlands
    PL: /^PL\d{10}$/,                        // Poland
    PT: /^PT\d{9}$/,                         // Portugal
    RO: /^RO\d{2,10}$/,                      // Romania
    SE: /^SE\d{12}$/,                        // Sweden
    SI: /^SI\d{8}$/,                         // Slovenia
    SK: /^SK\d{10}$/                         // Slovakia
  };
  const formRef = useRef(null); // Step 1: Create a ref for the form

  const normalizeLabel = (label) => {
    return label.trim().replace(/[\s\-]+/g, "");
  };
  

  const getValueInput = useCallback((value, label) => {
    const normalizedLabel = normalizeLabel(label);
    setFormData((prev) => ({ ...prev, [normalizedLabel]: value }));
  }, []);

  
  const fetchCountry = async () => {
    const [fetchCountries] = await Promise.all([
      middleware.get(`/countryCodes`),
    ]);
    setCountryInfo(fetchCountries.data);
  };

  useEffect(() => {
    try {
      fetchCountry();
    } catch (error) {
      console.warn(error)
    }
  }, [showCompany]);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Voorkom de standaardactie van het formulier

    if (countryIdInfo.length <= 0) {
      ToastError("Kies een land.");
      return;
    }

    if (formData.CocNumber && formData.CocNumber.length > 12) {
      ToastError("Mag niet meer dan 12 characters bij KVK nummer hebben");
      return
    }

    const selectedCountryCode = countryIdInfo.country_code_2;
    const vatRegex = vatRegexPatterns[selectedCountryCode];
  
    // if (formData.VatNumber && vatRegex && !vatRegex.test(formData.VatNumber)) {
    //   ToastError(`Ongeldig BTW-nummer formaat voor ${countryIdInfo.country}.`);
    //   return;
    // }

    let businessCreated = false; // Houd bij of het bedrijf succesvol is aangemaakt
    let businessId = null; // Sla het ID van het aangemaakte bedrijf op voor mogelijke compensatieactie
  
    try {
  
      await middleware.post("/addBusinesses", {
        company_name: formData.CompanyName,
        cocnumber: formData.CocNumber,
        vatnumber: formData.VatNumber,
        first_name: formData.FirstName,
        last_name: formData.LastName,
        country_code_id: countryIdInfo.country_code_id || null,
        emails: emails,
        phone_numbers: phoneNumbers,
        city: formData.City,
        streetname: formData.StreetName,
        birthday: formData.BirthDate,
        housenumber: formData.HouseNumber,
        addition: formData.Addition === "" ? null : formData.Addition,
        postalcode: formData.Postalcode,
        newsletter: formData.newsPaper == false ? 0 : 1,
        birthday_promotion: formData.birthday_promotion == false ? 0 : 1,
        address_kind: 2,
      });
  
      ToastSuccess("Bedrijf is aangemaakt");
      window.location.reload();
    } catch (err) {
      if (businessCreated) {
        // Als het bedrijf is aangemaakt maar het toevoegen van het adres faalt, voer dan een compensatieactie uit
        await middleware.delete(`/businesses/${businessId}`);
        ToastError("Aanmaken van bedrijf mislukt, bedrijfsrecord verwijderd.");
      } else {
        ToastError("Bedrijfsnaam, KVK nummer, BTW nummer, Voornaam, Achternaam, Email(minimaal 1), Postcode, Huisnummer, Straat en Stad zijn verplichte velden", 10000);

      }
      console.error(err);
    }
  };
  
  useEffect(() => {
    if (show) {
      setShowCompany(true);
    }
  }, [show]);

  const closeCompanyModal = () => {
    setShowCompany(false);
    close(true);
  };

  // Step 2: Define a method to programmatically submit the form
  const handleFormSubmit = (e) => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  const addEmailField = () => {
    if (emails.length < 3) {
      setEmails([...emails, '']);
    }
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const addPhoneNumberField = () => {
    if (phoneNumbers.length < 3) {
      setPhoneNumbers([...phoneNumbers, '']);
    }
  };

  const handlePhoneNumberChange = (index, value) => {
    const newPhoneNumbers = [...phoneNumbers];
    newPhoneNumbers[index] = value;
    setPhoneNumbers(newPhoneNumbers);
  };

  return (
    <Modal
        id="modalBlueprint"
        show={showCompany}
        onHide={() => closeCompanyModal()}
        
        size="xl"
        backdrop="static"
      >
        <Modal.Header className="modalTopCustomers justify-content-between">
          <div className='d-flex flex-row'>
            <h3 className='ms-3 text-nowrap me-3'>Bedrijf aanmaken</h3>
            <select
            className="form-select inputFieldAddCustomer dropdownsNewCustomers"
            onChange={(e) => setCountryIdInfo(JSON.parse(e.target.value))}
            >
              <option hidden>Kies een land</option>
              {countryInfo?.length > 0 && countryInfo.map(country => (
                <option key={country.country_code_id} value={JSON.stringify(country)}>
                  {country.country}
                </option>
              ))}
            </select>
          </div>

          <button variant="transparent" className=' btn' onClick={() => closeCompanyModal()}>
            <FontAwesomeIcon icon={faXmark} className="closeModal text-dark" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenterCustomers pt-0">
          <div className="contendModal w-100">
          <form className="col-12" ref={formRef} onSubmit={handleSubmit}>
            <div>
              <label className=' fw-bold mb-2 secondaryTitles'>Bedrijfsgegevens</label>
              <div className='col-12 d-flex flex-row align-content-end mb-3'>
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  alignmentInputs={"justify-content-start"}
                  parentCol={12}
                  labelName={"Bedrijfsnaam"}
                  labelValue={"CompanyName"}
                  classnamesInput={"col-12 SearchIcon px-3"}
                />
              </div>
              <div className="col-12 d-flex flex-row align-content-end mb-4">
                <InputComponent
                  type={"number"}
                  onValueChange={getValueInput}
                  alignmentInputs={"justify-content-start"}
                  parentCol={6}
                  labelName={"KVK nummer"}
                  labelValue={"CocNumber"}
                  min={0}
                  max={12}
                  classnamesInput={"col-12 widthInputComponents SearchIcon px-3"}
                />
                <InputComponent
                  type={"text"}
                  alignmentInputs={"justify-content-end"}
                  parentCol={6}
                  onValueChange={getValueInput}
                  labelName={"BTW nummer"}
                  labelValue={"VatNumber"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
              </div>
            </div>
            <div>
              <label className=' fw-bold mb-2 secondaryTitles'>Contactpersoon</label>
              <div className="col-12 d-flex flex-row align-content-end mb-3">
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  alignmentInputs={"justify-content-start"}
                  parentCol={6}
                  labelName={"Voornaam"}
                  labelValue={"FirstName"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
                <InputComponent
                  type={"text"}
                  alignmentInputs={"justify-content-end"}
                  parentCol={6}
                  onValueChange={getValueInput}
                  labelName={"Achternaam"}
                  labelValue={"LastName"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
              </div>
              <div className="col-12 d-flex flex-row align-content-end my-3">
                <div className="d-flex flex-column col-6 pe-3">
                  <div className="d-flex justify-content-between">
                    <label className='fw-semibold mb-2'>Emails</label>
                    <img
                      className='pe-3 addIconSizeModals'
                      src={Plus}
                      onClick={addEmailField}
                      alt="Add"
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  {emails.map((email, index) => (
                    <input
                      key={index}
                      className={`inputFieldAddCustomer mb-2 px-3`}
                      type="email"
                      required={true}
                      value={email}
                      onChange={(e) => handleEmailChange(index, e.target.value)}
                    />
                  ))}
                </div>
                <div className="d-flex flex-column col-6 ps-3">
                  <div className="d-flex justify-content-between">
                    <label className='fw-semibold mb-2'>Telefoonnummers</label>
                    <img
                      className='pe-3 addIconSizeModals'
                      src={Plus}
                      onClick={addPhoneNumberField}
                      alt="Add"
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  {phoneNumbers.map((phoneNumber, index) => (
                    <input
                      key={index}
                      className={`inputFieldAddCustomer mb-2 px-3`}
                      type="tel"
                      required={true}
                      value={phoneNumber}
                      onChange={(e) => handlePhoneNumberChange(index, e.target.value)}
                    />
                  ))}
                </div>
              </div>
              <div className="col-12 d-flex flex-row align-content-end my-3">
                <InputComponent
                  type={"date"}
                  onValueChange={getValueInput}
                  parentCol={6}
                  labelName={"Geboortedatum"}
                  labelValue={"BirthDate"}
                  classnamesInput={"col-12 widthInputComponents diffDateIcon px-3"}
                />
                <div className="col-6 d-flex justify-content-start">
                  <div className="col-4 d-flex flex-column">
                    <label className="mb-2 ms-4 fw-medium">Nieuwsbrief</label>
                    <label className="switchNewCustomer ms-4">
                      <input type="checkbox" />
                      <span className="sliderNewCustomer round"></span>
                    </label>
                  </div>
                  <div className=" col-4  d-flex flex-column">
                    <label className="mb-2 ms-4 fw-medium">Verjaardagactie</label>
                    <label className="switchNewCustomer ms-4">
                      <input type="checkbox" />
                      <span className="sliderNewCustomer round"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex flex-row align-content-end my-3">
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  parentCol={6}
                  labelName={"Postcode"}
                  labelValue={"Postalcode"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
                <div className="col-6 d-flex justify-content-end">
                  <div className="col-12 d-flex widthInputComponents flex-row justify-content-end">
                    <InputComponent
                      alignmentInputs={"justify-content-start"}
                      onValueChange={getValueInput}
                      type={"number"}
                      parentCol={6}
                      labelName={"Huisnummer"}
                      labelValue={"HouseNumber"}
                      classnamesInput={"col-12 widthInputComponents px-3"}
                    />
                    <InputComponent
                      alignmentInputs={"justify-content-end"}
                      onValueChange={getValueInput}
                      type={"text"}
                      parentCol={6}
                      labelName={"Toevoeging"}
                      labelValue={"Addition"}
                      classnamesInput={"col-12 widthInputComponents px-3"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex flex-row align-content-end my-3 ">
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  alignmentInputs={"justify-content-start"}
                  parentCol={6}
                  labelName={"Straat"}
                  labelValue={"StreetName"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  alignmentInputs={"justify-content-end"}
                  parentCol={6}
                  labelName={"Stad"}
                  labelValue={"City"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
              </div>
            </div>
          </form>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottomCustomers">
          <button
             type="button" // It's important to specify type="button" to prevent default button behavior
             className="btn addButtonCustomer px-5 rounded-pill"
             onClick={handleFormSubmit} // Use the new method for form submission
          >
            Bedrijf aanmaken
          </button>
        </Modal.Footer>
      </Modal>
  );
}

export default AddCompanyModal;
